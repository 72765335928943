
import React from "react";

import s from "./LinkedInCorner.module.scss";

const LinkedInCorner = () => (
  <div className={s.container}>
    <div>
      <footer className={s.corner}>
        <div>
          <div></div>
          <div>
            <a href="https://linkedin.com/in/krzszzz" target="_blank" rel="noreferrer noopener">
              <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 40 40">
                <path fill="#0094FF" d="M37.039,0H2.952C1.323,0,0,1.291,0,2.883v34.23c0,1.594,1.323,2.886,2.952,2.886h34.087 c1.632,0,2.961-1.292,2.961-2.886V2.883C40,1.291,38.671,0,37.039,0z M11.863,34.085H5.93V14.996h5.933V34.085z M8.898,12.386 c-1.901,0-3.44-1.542-3.44-3.44c0-1.897,1.54-3.439,3.44-3.439c1.897,0,3.438,1.542,3.438,3.439 C12.336,10.845,10.795,12.386,8.898,12.386z M34.085,34.085h-5.929v-9.283c0-2.214-0.041-5.062-3.083-5.062 c-3.088,0-3.561,2.412-3.561,4.901v9.443h-5.929V14.996h5.691v2.608h0.079c0.791-1.501,2.728-3.083,5.613-3.083 c6.008,0,7.117,3.953,7.117,9.093V34.085z" />
              </svg>
            </a>
          </div>
          <div></div>
          <div></div>
        </div>
      </footer>
    </div>
  </div>
);

export default LinkedInCorner;
