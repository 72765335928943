
import React, { useEffect } from "react";
import { TweenMax } from "gsap";
import { throttle } from "throttle-debounce";

import s from "./LobsterType.module.scss";
import sl from "../../layout/partials/Layout.module.scss";

const LobsterType = () => {

  useEffect(() => {

    const headlines = document.querySelectorAll(`.${s.container} h1`);
    let letters;
    let clientX;
    let clientY;

    const renderType = (items) => {
      items.forEach((headline) => {
        const headlineText = headline.innerHTML;
        headline.innerHTML = "<span></span><span></span>";
        const headlineWraps = headline.querySelectorAll("span");
        
        headlineWraps.forEach((headlineWrap) => {
          Array.prototype.forEach.call(headlineText, (letter) => {

            if (letter === "|") {
              headlineWrap.innerHTML += "<br>";
            } else {
              const outerLetterWrap = document.createElement("b");
              const innerLetterWrap = document.createElement("em");
    
              headlineWrap.appendChild(outerLetterWrap);
              outerLetterWrap.appendChild(innerLetterWrap);
    
              for (var i = 0; i < 6; i++) {
                innerLetterWrap.innerHTML += `<i><u>${letter}</u></i>`;
              }
            }
    
          });
        });
      });

      letters = document.querySelectorAll(`.${s.container} h1 b`);
    };

    const centerOf = (element) => {
      const posX = element.getBoundingClientRect().left + element.getBoundingClientRect().width / 2;
      const posY = element.getBoundingClientRect().top + element.getBoundingClientRect().height / 2 - document.body.getBoundingClientRect().top;
      const modX = Math.abs((window.innerWidth - clientX) / window.innerWidth - 1) - Math.abs((window.innerWidth - posX) / window.innerWidth - 1);
      const modY = Math.abs((window.innerHeight - clientY) / window.innerHeight - 1) - Math.abs((window.innerHeight - posY) / window.innerHeight - 1);
    
      return {
        modX,
        modY,
      }
    };

    const moveType = throttle(123, (event) => {
      clientX = event.touches ? event.touches[0].clientX : event.clientX;
      clientY = event.touches ? event.touches[0].clientY : event.clientY;
    
      headlines.forEach((headline) => {
        TweenMax.to(headline, 1, {
          rotationY: centerOf(headline).modX * 16,
          rotationX: centerOf(headline).modY * -22,
          // z: 33,
        });
    
        TweenMax.to(headline.querySelector("span:last-of-type"), 1.5, {
          x: centerOf(headline).modX * -37,
          y: centerOf(headline).modY * -37,
        });
      });
    
      letters.forEach((letter) => {
        const modX = centerOf(letter).modX;
        const modY = centerOf(letter).modY;
        const mod = .77;
    
        TweenMax.to(letter.querySelector("i:nth-of-type(2)"), .5, {
          x: mod * modX * 15,
          y: mod * modY * 7,
          width: `${42 - mod * modX * 15}%`,
        });
    
        TweenMax.to(letter.querySelector("i:nth-of-type(3)"), .5, {
          x: mod * modX * 10,
          y: mod * -modY * 7,
          width: `${42 - mod * modX * 30}%`,
        });
    
        TweenMax.to(letter.querySelector("i:nth-of-type(4)"), .5, {
          x: mod * -modX * 15,
          y: mod * modY * 7,
          width: `${42 + mod * modX * 30}%`,
        });
    
        TweenMax.to(letter.querySelector("i:nth-of-type(5)"), .5, {
          x: mod * modX * 17,
          y: mod * -modY * 7,
          width: `${42 - mod * modX * 30}%`,
        });
    
        TweenMax.to(letter.querySelector("i:nth-of-type(6)"), .5, {
          x: mod * -modX * 8,
          y: mod * modY * 9,
          width: `${42 + mod * modX * 15}%`,
        });
      });
    });
    
    renderType(headlines);

    window.addEventListener("mousemove", moveType);
    window.addEventListener("touchmove", moveType);

    return () => {
      window.removeEventListener("mousemove", moveType);
      window.removeEventListener("touchmove", moveType);
      moveType.cancel();
    }

  }, []);

  useEffect(() => { // small hack for ui's header shadow opacity
    TweenMax.set(`.${sl.content__shadow}`, { opacity: .5 });
    return () => TweenMax.set(`.${sl.content__shadow}`, { clearProps: "all" });
  }, []);

  return (
    <>
      <div className={s.container}>
        <h1>Halldor|Pro</h1>
        <div className={s.image} />
      </div>
    </>
  );
};

export default LobsterType;
