
import React, { useEffect } from "react";

import s from "./ResponsiveCollapsibleGrid.module.scss";

const ResponsiveCollapsibleGrid = () => {

  const text = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam placerat ipsum id scelerisque egestas.",
    "Suspendisse volutpat ex ultrices dolor molestie, sed feugiat nulla pretium. Duis vehicula nisi sem, vitae tincidunt risus ultricies eu. Praesent tincidunt vehicula urna vel volutpat. Nullam at risus quis diam ullamcorper gravida eu lobortis neque.",
    "Donec vehicula ex mauris, in maximus elit pharetra vitae. Phasellus nec luctus sapien. Vivamus dictum vel tellus nec elementum. Vestibulum massa justo, malesuada vitae hendrerit sit amet, dapibus nec lacus.",
    "Etiam ultricies dolor nec vehicula malesuada. Phasellus convallis quis nisl sed fermentum. Etiam id lacus ac justo iaculis commodo. Duis et nisl tempor, rutrum massa at, bibendum nisl.",
    "Aenean mattis diam odio, euismod ornare massa sagittis quis. Suspendisse tempor a velit nec elementum.",
    "Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin at dui nisl. Cras finibus ornare orci, non finibus mi tincidunt ac. Quisque ultricies metus ipsum, id iaculis dolor molestie in.",
    "Etiam et luctus felis. Etiam sodales volutpat dolor vel maximus. Praesent iaculis elit et metus aliquet, ut aliquet tortor tempus. Morbi quis risus velit.",
    "Suspendisse rhoncus urna at maximus faucibus. Proin condimentum congue congue.",
    "Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed faucibus lectus, condimentum maximus ante.",
    "Duis viverra, velit ut eleifend vestibulum, lectus elit suscipit odio, et commodo libero est non neque. Morbi magna mi, feugiat vitae massa et, consectetur vestibulum odio.",
    "Sed lacinia sed mauris id dignissim. Nunc ex eros, consequat non tincidunt non, ultricies vel tellus. Sed id dictum tortor.",
    "Nam non rhoncus augue. Praesent nulla massa, volutpat cursus congue eget, ornare eget velit. Vivamus vestibulum nec risus sed pharetra.",
    "Curabitur suscipit, dolor tempus molestie luctus, neque tellus tristique velit, at facilisis mauris sapien a mi. Sed viverra vitae dui vitae egestas. Fusce suscipit lorem quis nibh lobortis elementum. Nullam placerat odio in orci laoreet elementum sit amet tempus justo.",
    "Aliquam pulvinar arcu sit amet lectus laoreet, sit amet fermentum dolor vulputate. Vestibulum efficitur, massa vitae porttitor aliquet, ante lorem auctor justo, sed auctor tellus justo at eros.",
    "Etiam tellus augue, placerat et leo vitae, tincidunt consectetur elit. Pellentesque porta volutpat nisl, at viverra lorem posuere eu. Aliquam eget egestas neque.",
    "Phasellus posuere, nulla vel sagittis accumsan, mi ipsum accumsan magna, quis egestas erat leo vel felis. Nam mi ligula, consectetur sit amet tincidunt sed, laoreet id sapien.",
    "Morbi elementum nulla in blandit ultrices. Quisque viverra interdum tellus, vitae rhoncus justo venenatis consequat. Vestibulum in ante pellentesque odio posuere congue in id nunc.",
    "Proin in accumsan nunc. Mauris non rhoncus urna. Nulla ante leo, molestie id finibus porta, rhoncus malesuada massa.",
    "Nullam a tortor et elit finibus porttitor convallis vel augue. Nullam maximus justo ultrices, posuere dolor non, elementum sem.",
    "Integer convallis suscipit lacus, egestas sagittis lectus consequat quis. Morbi viverra dui ut tincidunt venenatis. Quisque eget pretium felis.",
  ];

  useEffect(() => {

    const grid = document.querySelector(`.${s.grid}`);
    const items = document.querySelectorAll(`.${s.item}`);
    const transition = 500;

    const nothingSelected = () => {
      let status = true;
      Array.prototype.forEach.call(items, item => {
        if (item.classList.length > 1) status = false;
      });
      return status;
    };

    const resetGrid = () => {
      Array.prototype.forEach.call(items, item => {
        item.classList.remove(s.item____selected);
        item.style.marginBottom = 0;
        item.querySelector(`.${s.item__body}`).style.height = 0;
      });
    };

    const setHeights = () => {
      Array.prototype.forEach.call(items, item => {
        item.removeAttribute("style");
        item.querySelector(`.${s.item__body}`).removeAttribute("style");

        let height = 0;

        item.classList.add(s.item____selected);
        height = item.querySelector(`.${s.item__body}`).getBoundingClientRect().height;
        item.classList.remove(s.item____selected);
        item.setAttribute("data-height", height);
      });

      resetGrid();
    };

    const disableClicks = (state) => {
      if (state) {
        grid.style.pointerEvents = "none";
      } else {
        grid.removeAttribute("style");
      }
    };

    const handleClick = (event) => {
      const item = event.currentTarget;
      const body = item.querySelector(`.${s.item__body}`);
      const bodyHeight = `${item.getAttribute("data-height")}px`;

      disableClicks(true);

      if (nothingSelected()) {
        item.classList.add(s.item____selected);
        item.style.marginBottom = bodyHeight;
        body.style.height = bodyHeight;
        setTimeout(() => disableClicks(false), transition);
      } else {
        if (item.classList.contains(s.item____selected)) {
          item.classList.remove(s.item____selected);
          item.style.marginBottom = 0;
          body.style.height = 0;
          setTimeout(() => disableClicks(false), transition);
        } else {
          resetGrid();
          setTimeout(() => {
            item.classList.add(s.item____selected);
            item.style.marginBottom = bodyHeight;
            body.style.height = bodyHeight;
            disableClicks(false);
          }, transition);
        }
      }
    };

    setHeights();
    window.addEventListener("resize", setHeights);

    Array.prototype.forEach.call(items, (item) => {
      item.addEventListener("click", handleClick);
    });

    return () => {
      window.removeEventListener("resize", setHeights);
      Array.prototype.forEach.call(items, (item) => {
        item.removeEventListener("click", handleClick);
      });
    }

  }, []);

  return (
    <div className={s.container}>
      <div>
        <ul className={s.grid}>
          {text.map((item, index) => (
            <li className={s.item} key={`item-${index + 1}`}>
              <div className={s.item__head}>{index + 1}</div>
              <div className={s.item__body}>
                <div>{item}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ResponsiveCollapsibleGrid;
