
import React, { useEffect } from "react";
import { Linear, Power4, TimelineMax, TweenMax } from "gsap";
import { throttle } from "throttle-debounce";

import s from "./PixelExplosion.module.scss";

const PixelExplosion = () => {

  useEffect(() => {

    const pixelsWrapper = document.querySelector(`.${s.container} ul`);
    let pixels = null;

    const status = {
      clickable: true,
      movable: false,
      exploded: false,
    };

    const pixelArt =
      "00000   0000   0000  00   00 00" +
      "000000 000000 000000 000 000 00" +
      "00  00 00  00 00  00 0000000 00" +
      "00  00 00  00 00  00 00 0 00 00" +
      "00000  00  00 00  00 00   00 00" +
      "00000  00  00 00  00 00   00 00" +
      "00  00 00  00 00  00 00   00 00" +
      "00  00 00  00 00  00 00   00   " +
      "000000 000000 000000 00   00 00" +
      "00000   0000   0000  00   00 00";

    // const pixelArt = 
    //   "                 000000" +
    //   "                       " +
    //   "                       " +
    //   "00  00   0000    00  00" +
    //   "00  00  000000   00  00" +
    //   "00  00  00  00   00  00" +
    //   "00 00   00  00    00 00" +
    //   "00 00   00  00    00 00" +
    //   "0000     0000      0000" +
    //   "0000     0000      0000" +
    //   "00 00   00  00    00 00" +
    //   "00 00   00  00    00 00" +
    //   "00  00  00  000  00  00" +
    //   "00  00  0000000  00  00" +
    //   "00  00   000 00  00  00" +
    //   "                       " +
    //   "                       " +
    //   "000000                 ";

    const preparePixels = () => {
      const pixelArtArray = [];
      const pixelSize = 1.6; // using vw units
      const lineWidth = 31; // 23;

      TweenMax.set(pixelsWrapper, { width: `${pixelSize * lineWidth}vw` });

      for (let i = 0; i < pixelArt.length; i++) {
        pixelArtArray.push(pixelArt[i]);
        const pixel = document.createElement("li");
        pixelsWrapper.appendChild(pixel);
      }

      pixels = document.querySelectorAll(`.${s.container} li`);

      Array.prototype.forEach.call(pixels, (_, index) => {
        TweenMax.set(pixels[index], { width: `${pixelSize}vw` });
        if (pixelArtArray[index] == "0") pixels[index].classList.add(s.o);
      });

      // console.log(`${pixelArtArray.length} pixels rendered`);
      TweenMax.fromTo(pixelsWrapper, 1, {
        opacity: 0,
        scale: .9,
      }, {
        ease: Power4.easeOut,
        opacity: 1,
        scale: 1,
      });
    };

    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const handleClick = () => {
      if (!status.exploded) {
        explodePixels();
      } else {
        implodePixels();
      }
    }

    const handleMouseMove = throttle(123, (event) => {
      if (status.movable) {
        TweenMax.to(pixelsWrapper, 3, {
          rotationX: (-event.pageY + window.innerHeight / 2) * -.007,
          rotationY: (-event.pageX + window.innerWidth / 2) * .006,
          ease: Power4.easeOut,
        });
      } else {
        TweenMax.to(pixelsWrapper, 1, {
          rotationX: 0,
          rotationY: 0,
          ease: Power4.easeOut,
        });
      }
    });

    const explodePixels = () => {
      if (!status.clickable) return;

      status.clickable = false;
      status.movable = true;

      const durations = [];

      TweenMax.to(pixelsWrapper, .1, {
        scale: .9,
        ease: Power4.easeIn,
      });

      Array.prototype.forEach.call(pixels, (item, index) => {
        const pixelItem = pixels[index];

        // quit here if pixel is not suppose to be animated
        if (!pixelItem.classList.contains(s.o)) return;

        const pixelAnimation = new TimelineMax();
        const pixelProp = {
          duration: .15 + randomInt(0, 16) / 10 + randomInt(0, 1) * randomInt(0, 15),
          xPosition: randomInt(-10, 10) * 10,
          yPosition: randomInt(-10, 10) * 10,
          zPosition: randomInt(1, 15) * 40,
          xRotation: randomInt(-15, 15) * 10,
          zRotation: randomInt(-15, 15) * 10,
          xRotationMultiplier: 1 + randomInt(0, 6) / 3,
          zRotationMultiplier: 1 + randomInt(0, 6) / 3,
          yRotationDuration: 1 + randomInt(1, 20) / 5,
          delay: randomInt(0, 10) / 44 + .1,
        };

        durations.push(pixelProp.duration + pixelProp.delay);

        pixelAnimation
          .timeScale(1.2)
          .set(pixelItem, {
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0
          })
          .to(pixelItem, pixelProp.duration, {
            x: pixelProp.xPosition,
            y: pixelProp.yPosition,
            z: pixelProp.zPosition,
            rotationX: pixelProp.xRotation * pixelProp.xRotationMultiplier,
            rotationZ: pixelProp.zRotation * pixelProp.zRotationMultiplier,
            delay: pixelProp.delay,
            ease: Power4.easeOut,
          }, 0)
          .to(pixelItem, pixelProp.yRotationDuration, {
            rotationY: 360,
            ease: Linear.easeNone,
            repeat: -1,
          }, 0);
          // }, pixelProp.duration * .8);

      });

      // const maxDuration = Math.max.apply(Math, durations);

      // TweenMax.to(pixelsWrapper, maxDuration, {
      TweenMax.to(pixelsWrapper, 2, {
        scale: 1.5,
        // delay: .25,
        ease: Power4.easeOut,
        onComplete: () => {
          status.clickable = true;
          status.exploded = true;
        },
      });
    };

    const implodePixels = () => {
      if (!status.clickable) return;

      status.clickable = false;
      status.movable = false;

      const durations = [];

      // TweenMax.to(pixelsWrapper, .1, {
      //   scale: 1.6,
      //   ease: Linear.easeNone,
      // });

      Array.prototype.forEach.call(pixels, (item, index) => {
        const pixelItem = pixels[index];

        TweenMax.killTweensOf(pixelItem);

        // quit here if pixel is not suppose to be animated
        if (!pixelItem.classList.contains(s.o)) return;

        const pixelAnimation = new TimelineMax();
        const pixelProp = {
          duration: .1 + randomInt(0, 10) / 10,
        };

        durations.push(pixelProp.duration);

        pixelAnimation
          .to(pixelItem, pixelProp.duration, {
            x: 0,
            y: 0,
            z: 0,
            rotationX: 360,
            rotationY: 360,
            rotationZ: 360,
            ease: Power4.easeOut,
          });
      });

      const maxDuration = Math.max.apply(Math, durations);

      TweenMax.to(pixelsWrapper, maxDuration, {
      // TweenMax.to(pixelsWrapper, .5, {
        scale: 1,
        ease: Power4.easeOut,
        onComplete: () => {
          status.clickable = true;
          status.exploded = false;
        },
      });
    };

    // init
    // preparePixels();
    // delayed init (allow layout to load)
    // TODO
    setTimeout(preparePixels, 500);
    pixelsWrapper.addEventListener("click", handleClick);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      handleMouseMove.cancel();
    }

  }, []);

  return (
    <div className={s.container}>
      <ul />
    </div>
  );
};

export default PixelExplosion;
