
import React, { useEffect } from "react";
import { Back, Power4, TimelineLite, TweenMax } from "gsap";

import s from "./KosmosOgKaos.module.scss";

const KosmosOgKaos = () => {

  useEffect(() => {

    const color = {
      purple: "#65627a",
      red: "#f2756b",
      white: "#fff",
    };

    let interval = 1;

    const rotateStuff = () => {
      TweenMax.to("#kogk-and", .8, {
        rotation: 180 * interval,
        ease: Power4.easeInOut,
        onComplete: () => {
          if (interval % 2 == 0) {
            TweenMax.to(`.${s.back}`, .2, { backgroundColor: color.red });
            TweenMax.to(`.${s.logo} svg path`, .2, { fill: color.purple });
          } else {
            TweenMax.to(`.${s.back}`, .2, { backgroundColor: color.purple });
            TweenMax.to(`.${s.logo} svg path`, .2, { fill: color.red });
          }
        },
      });

      setTimeout(() => {
        TweenMax.to(`.${s.logo__element}`, .8, {
          rotation: 180 * interval,
          ease: Power4.easeInOut,
        });
        setTimeout(() => {
          lettersKosmos.play();
          lettersKaos.play();
          interval++;
        }, 500);
      }, 500);
    };

    const lettersKosmos = new TimelineLite({
      onComplete: () => { 
        setTimeout(() => {
          lettersKosmos.reverse();
          lettersKaos.reverse();
        }, 400);
      },
      onReverseComplete: rotateStuff,
    });

    const lettersKaos = new TimelineLite();

    // initial positioning set up
    TweenMax.killAll();
    TweenMax.set(`.${s.logo__element}`, { transformOrigin: "59.6% 50%", scale: 18, rotation: -1900 });
    TweenMax.set("#kogk-line-1", { scaleX: .15, transformOrigin: "top right" });
    TweenMax.set("#kogk-11", { x: 222, transformOrigin: "bottom left" });
    TweenMax.set("#kogk-and", { transformOrigin: "center" });
    TweenMax.set("#kogk-3 #kogk-line-3", { scaleX: .24 });
    TweenMax.set("#kogk-3 #kogk-31", { x: -123 });
    TweenMax.set("#kogk-12, #kogk-13, #kogk-14, #kogk-15, #kogk-16", { scaleY: 0, transformOrigin: "bottom left" });
    TweenMax.set("#kogk-32, #kogk-33, #kogk-34", { scaleY: 0 });

    lettersKosmos.pause();
    lettersKaos.pause();

    TweenMax.to(`.${s.logo__element}`, 1.4, {
      rotation: 0,
      scale: 1,
      ease: Power4.easeOut,
      onComplete: () => {
        lettersKosmos.play();
        lettersKaos.play();
      },
    });

    // animations
    lettersKosmos
      .to("#kogk-1 #kogk-line-1", .95, { scaleX: 1, ease: Power4.easeInOut })
      .to("#kogk-1 #kogk-11", .95, { x: 0, ease: Power4.easeInOut }, -0)
      .to("#kogk-1 #kogk-16", .35, { scaleY: 1, ease: Back.easeOut }, .32)
      .to("#kogk-1 #kogk-15", .35, { scaleY: 1, ease: Back.easeOut }, .42)
      .to("#kogk-1 #kogk-14", .35, { scaleY: 1, ease: Back.easeOut }, .48)
      .to("#kogk-1 #kogk-13", .35, { scaleY: 1, ease: Back.easeOut }, .56)
      .to("#kogk-1 #kogk-12", .35, { scaleY: 1, ease: Back.easeOut }, .68);
    
    lettersKaos
      .to("#kogk-3 #kogk-line-3", 1.05, { scaleX: 1, ease: Power4.easeInOut })
      .to("#kogk-3 #kogk-31", 1.05, { x: 0, ease: Power4.easeInOut }, -0)
      .to("#kogk-3 #kogk-34", .35, { scaleY: 1, ease: Back.easeOut }, .43)
      .to("#kogk-3 #kogk-33", .35, { scaleY: 1, ease: Back.easeOut }, .55)
      .to("#kogk-3 #kogk-32", .35, { scaleY: 1, ease: Back.easeOut }, .7);

    return () => {
      TweenMax.killAll();
    };

  }, []);

  return (
    <div className={s.logo}>
      <div className={s.logo__element}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.8 116" width="510.8" height="116">
        <g id="kogk-3">
          <path id="kogk-31" d="M488.8,21.7l7.3,34.3V21.7h14.5v72.5h-14.5V60.1L488,94.2h-15l9.5-35.7l-9.1-36.8H488.8"/>
          <path id="kogk-32" d="M432.6,21.7H447l1.1,10.6h9.1l1-10.6h13.1l-9.5,72.5h-19.8L432.6,21.7 M455.9,44.9h-6.5l3.3,31.7 L455.9,44.9z"/>
          <path id="kogk-33" d="M405.4,21.1h9.3c9,0,13.5,4.5,13.5,13.5v46.8c0,9-4.5,13.5-13.5,13.5h-9.3c-9,0-13.5-4.5-13.5-13.5 V34.5C392,25.5,396.4,21.1,405.4,21.1 M409,82.4h2.1c1.8,0,2.6-0.8,2.6-2.6V36.1c0-1.8-0.8-2.6-2.6-2.6H409c-1.8,0-2.6,0.8-2.6,2.6 v43.7C406.5,81.5,407.3,82.4,409,82.4z"/>
          <path id="kogk-34" d="M350.3,47.9V34.5c0-9,4.5-13.5,13.5-13.5h8.1c9,0,13.5,4.5,13.5,13.5V50h-14.2V35.9 c0-1.8-0.8-2.6-2.6-2.6h-1.3c-1.8,0-2.6,0.8-2.6,2.6v8.7c0,2.3,0.7,3.4,2.5,4.7l12.4,9.2c4.5,3.2,5.8,6.2,5.8,11.4v11.5 c0,9-4.5,13.5-13.5,13.5H364c-9,0-13.5-4.5-13.5-13.5V68.1h14.2V80c0,1.8,0.8,2.6,2.6,2.6h1.1c1.8,0,2.6-0.8,2.6-2.6v-6.8 c0-2.3-0.7-3.4-2.5-4.7l-12.4-9.2C351.7,56.1,350.3,53.3,350.3,47.9"/>
          <path id="kogk-line-3" d="M349.5,0h161.3v10H349.5V0z"/>
        </g>
        <g id="kogk-2">
          <path id="kogk-and" d="M312,88.4c-2.3,4.5-5.3,6.5-11.4,6.5h-5.5c-8.4,0-12.6-4.2-12.6-12.6V67.7c0-5.1,1.1-9,4.9-12.2 l4-3.5l-2.4-4.3c-2.2-4-2.5-6.5-2.5-10.7v-2.3c0-9,4.5-13.5,13.5-13.5h6.2c9,0,13.5,4.5,13.5,13.5v9.6h-13.4v-8.2 c0-1.8-0.8-2.6-2.6-2.6h-1c-1.8,0-2.6,0.8-2.6,2.6v1.5c0,2.7,0.7,4.5,2.1,6.9l9,16.6l3.1-9.7h14.2l-8.6,23.9l10.4,19h-14.9 L312,88.4 M303.3,80.7l1.6-3.9l-6.9-12.6c-1.3,1.2-1.8,2.4-1.8,4.7v11.9c0,1.6,0.7,2.3,2.3,2.3h1.9 C301.8,82.9,302.7,82.3,303.3,80.7z"/>
        </g>
        <g id="kogk-1">
          <path id="kogk-11" d="M21.8,94.3l-7.3-34.2v34.2H0V21.9h14.5v34l8.1-34h15l-9.5,35.6l9.1,36.8H21.8"/>
          <path id="kogk-12" d="M64.1,94.9h-9.3c-9,0-13.5-4.5-13.5-13.5V34.7c0-9,4.5-13.5,13.5-13.5h9.3c9,0,13.5,4.5,13.5,13.5 v46.8C77.5,90.5,73.1,94.9,64.1,94.9 M60.5,33.7h-2.1c-1.8,0-2.6,0.8-2.6,2.6v43.7c0,1.8,0.8,2.6,2.6,2.6h2.1 c1.8,0,2.6-0.8,2.6-2.6V36.3C63,34.5,62.2,33.7,60.5,33.7z"/>
          <path id="kogk-13" d="M119.2,68.1v13.4c0,9-4.5,13.5-13.5,13.5h-8.1c-9,0-13.5-4.5-13.5-13.5V66.1h14.2v14.1 c0,1.8,0.8,2.6,2.6,2.6h1.3c1.8,0,2.6-0.8,2.6-2.6v-8.7c0-2.3-0.7-3.4-2.5-4.7L90,57.6c-4.5-3.2-5.8-6.2-5.8-11.4V34.7 c0-9,4.5-13.5,13.5-13.5h7.9c9,0,13.5,4.5,13.5,13.5v13.3h-14.2V36c0-1.8-0.8-2.6-2.6-2.6H101c-1.8,0-2.6,0.8-2.6,2.6v6.8 c0,2.3,0.7,3.4,2.5,4.7l12.4,9.2C117.8,60,119.2,62.8,119.2,68.1"/>
          <path id="kogk-14" d="M150.7,65.7l4.4-43.8h21v72.4h-14.3V46.8l-5.5,47.5h-13.6l-5.5-48v48h-11.6V21.9h20.9L150.7,65.7"/>
          <path id="kogk-15" d="M205.9,94.9h-9.3c-9,0-13.5-4.5-13.5-13.5V34.7c0-9,4.5-13.5,13.5-13.5h9.3c9,0,13.5,4.5,13.5,13.5 v46.8C219.4,90.5,214.9,94.9,205.9,94.9 M202.3,33.7h-2.1c-1.8,0-2.6,0.8-2.6,2.6v43.7c0,1.8,0.8,2.6,2.6,2.6h2.1 c1.8,0,2.6-0.8,2.6-2.6V36.3C204.9,34.5,204,33.7,202.3,33.7z"/>
          <path id="kogk-16" d="M261,68.1v13.4c0,9-4.5,13.5-13.5,13.5h-8.1c-9,0-13.5-4.5-13.5-13.5V66.1h14.2v14.1 c0,1.8,0.8,2.6,2.6,2.6h1.3c1.8,0,2.6-0.8,2.6-2.6v-8.7c0-2.3-0.7-3.4-2.5-4.7l-12.4-9.2c-4.5-3.2-5.8-6.2-5.8-11.4V34.7 c0-9,4.5-13.5,13.5-13.5h7.9c9,0,13.5,4.5,13.5,13.5v13.3h-14.2V36c0-1.8-0.8-2.6-2.6-2.6h-1.1c-1.8,0-2.6,0.8-2.6,2.6v6.8 c0,2.3,0.7,3.4,2.5,4.7l12.4,9.2C259.6,60,261,62.8,261,68.1"/>
          <path id="kogk-line-1" d="M0.4,106h259.4v10H0.4V106z"/>
        </g> 
        </svg>
      </div>
      <div className={s.back} />
    </div>
  );
};

export default KosmosOgKaos;
