
import React, { useContext, useEffect } from "react";
import { TweenMax } from "gsap";

import { PageContext } from "../../layout";

import s from "./BlurryButtons.module.scss";

const BlurryButtons = () => {

  const page = useContext(PageContext);

  useEffect(() => {

    const buttons = document.querySelectorAll(`.${s.container} .${s.button}`);

    const initButton = (button) => {
      const mask = document.createElement("div");
      mask.classList.add(s.mask);
      const background = document.createElement("div");
      background.classList.add(s.mask__background);
      
      button.parentElement.appendChild(mask);
      mask.appendChild(background);

      setMask(button, mask, background);
      window.addEventListener("resize", () => setMask(button, mask, background));
    };

    const setMask = (button, mask, background) => {
      const leftMod = mask.parentElement.getBoundingClientRect().left; // left & right mods just in case the container isn't full page width
      const rightMod = 0;
      const marginMod = 66; // extra margin mod set to counter original image's margins
      const width = button.getBoundingClientRect().width;
      const height = button.getBoundingClientRect().height;

      const top = button.getBoundingClientRect().top;
      const left = button.getBoundingClientRect().left - leftMod;
      const right = window.innerWidth - left - width - leftMod - rightMod;
      const bottom = window.innerHeight - top - height;

      mask.style.top = `${top}px`;
      mask.style.right = `${right}px`;
      mask.style.bottom = `${bottom}px`;
      mask.style.left = `${left}px`;

      background.style.top = `${-top + marginMod}px`;
      background.style.right = `${-right + marginMod}px`;
      background.style.bottom = `${-bottom + marginMod}px`;
      background.style.left = `${-left + marginMod}px`;
    };

    setTimeout(() => {
      // initialize all buttons on page
      Array.prototype.forEach.call(buttons, (button) => {
        new initButton(button);
      });
  
      // setting blur ratio, just for demo purposes
      Array.prototype.forEach.call(buttons, (button) => {
        button.addEventListener("click", () => {
          document.querySelector(`.${s.container}`).setAttribute("data-blur", button.getAttribute("data-blur"));
        });
      });
    }, 1000);

    return () => {
      Array.prototype.forEach.call(buttons, () => {
        window.removeEventListener("resize", setMask);
      });
    }

  }, []);

  // TODO - fix the shadow + images
  useEffect(() => { // small hack to reposition buttons after ui minimization
    const buttons = document.querySelector(`.${s.buttons}`);
    const masks = document.querySelectorAll(`.${s.container} .${s.mask}`);

    TweenMax.to([buttons, masks], .2, { opacity: 0, });

    setTimeout(() => {
      const event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, true);
      window.dispatchEvent(event);
      
      TweenMax.to([buttons, masks], .5, { opacity: 1 });
    }, 670);
  }, [page.isUiMinimized]);

  return (
    <div className={s.container} data-blur="8">
      <div className={s.buttons}>
        <a href="#" className={s.button} data-blur="2"><span>2</span></a>
        <a href="#" className={s.button} data-blur="4"><span>4</span></a>
        <a href="#" className={s.button} data-blur="8"><span>8</span></a>
        <a href="#" className={s.button} data-blur="16"><span>16</span></a>
        <a href="#" className={s.button} data-blur="32"><span>32</span></a>
      </div>
    </div>
  );
};

export default BlurryButtons;
