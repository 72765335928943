
import React, { useEffect, useRef } from "react";
import { Power4, TweenMax } from "gsap";
import { throttle } from "throttle-debounce";

import s from "./MousemoveFloating.module.scss";

const MousemoveFloatingAway = () => {

  const icons = useRef(null);

  useEffect(() => {

    const moveIcons = throttle(123, (event) => {
      const globalX = event ? event.touches ? event.touches[0].clientX : event.clientX : 0;
      const globalY = event ? event.touches ? event.touches[0].clientY : event.clientY : 0;

      icons.current.querySelectorAll(`.${s.icon}`).forEach(icon => {
        if (!icon) return;

        const localX = icon.getBoundingClientRect().left + icon.getBoundingClientRect().width / 2;
        const localY = icon.getBoundingClientRect().top + icon.getBoundingClientRect().height / 2;

        const distance = Math.hypot(localX - globalX, localY - globalY);
        const distanceMax = 222;
        const distanceMod = 4;
        const distanceX = Math.hypot(localX - globalX);
        const distanceY = Math.hypot(localY - globalY);
        let x;
        let y;

        if (distance < distanceMax) {
          if (globalX < localX) {
            x = distanceX < distanceMax / 2 ? localX - globalX : distanceMax - (localX - globalX);
          } else {
            x = distanceX < distanceMax / 2 ? localX - globalX : (globalX - localX) - distanceMax;
          }
  
          if (globalY < localY) {
            y = distanceY < distanceMax / 2 ? localY - globalY : distanceMax - (localY - globalY);
          } else {
            y = distanceY < distanceMax / 2 ? localY - globalY : (globalY - localY) - distanceMax;
          }

          TweenMax.to(icon, 1, {
            ease: Power4.easeOut,
            // x: localX - globalX, // TODO regular behaviour
            // y: localY - globalY,
            x: x / distanceMod,
            y: y / distanceMod,
          });
        } else {
          TweenMax.to(icon, 1, {
            ease: Power4.easeOut,
            x: 0,
            y: 0,
          });
        }
      });
    });

    window.addEventListener("mousemove", moveIcons);

    return () => {
      window.removeEventListener("mousemove", moveIcons);
      moveIcons.cancel();
    };

  }, []);

  return (
    <div className={s.container}>
      <div className={s.icons} ref={icons}>
        {Array.from({ length: 150 }, (_, index) => (
          <div key={`icon-${index}`}>
            <div className={s.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MousemoveFloatingAway;
