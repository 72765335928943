
import React, { useContext } from "react";
import classNames from "classnames";

import { PageContext } from "../../layout";

import s from "./FixedClippedHeaders.module.scss";

const FixedClippedHeaders = () => {

  const page = useContext(PageContext);
  const headers = [
    "Lorem",
    "Ipsum",
    "Dolor sit",
    "Adipiscing",
    "Sem ac elit",
    "Accumsan",
    "Sollicitudin",
    "Dapibus",
  ];

  return (
    <div className={classNames(s.ui, { [s.ui____minimized]: page.isUiMinimized })}>
      {headers.map((header, index) => (
        <section className={s.section} key={`header-${index}`}>
          <div className={s.section__head}>
            <h1>{header}</h1>
          </div>
          <div className={s.section__content}>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pellentesque sem ac elit accumsan, sollicitudin dapibus magna posuere. Etiam faucibus, turpis eu rutrum rutrum, dolor augue eleifend erat, id pharetra libero arcu eget est. Maecenas sollicitudin, justo in hendrerit molestie, nisl tellus rutrum nunc, quis lacinia metus velit in magna.</p>
          </div>
        </section>
      ))}
    </div>
  );
};

export default FixedClippedHeaders;
