
import React, { useEffect } from "react";
import { Power3, TweenMax } from "gsap";

import Image1 from "../../images/snowboarding-1.jpg";
import Image2 from "../../images/snowboarding-2.jpg";
import Image3 from "../../images/snowboarding-3.jpg";

import s from "./ZoomAndPan.module.scss";

const ZoomAndPan = () => {

  useEffect(() => {

    const tiles = document.querySelectorAll(`.${s.tile}`);

    const onHover = (event) => {
      const photo = event.currentTarget.querySelector(`.${s.photo}`);
      const scale = event.currentTarget.getAttribute("data-scale");

      if (event.type === "mouseover") {
        TweenMax.to(photo, scale / 2, {
          scale: scale,
          ease: Power3.easeOut,
        });
      } else if (event.type === "mouseout") {
        TweenMax.to(photo, scale / 2, {
          scale: 1,
          ease: Power3.easeOut,
        });
      }
    };

    const onMove = (event) => {
      const tile = event.currentTarget;
      const photo = tile.querySelector(`.${s.photo}`);
      const container = tile.parentElement;
      const x = event.clientX;
      const y = event.clientY;

      // TODO - tweak the transition, check on transform-origin animatable props
      TweenMax.to(photo, 1, {
        transformOrigin: `${((x - tile.offsetLeft - container.getBoundingClientRect().left) / photo.offsetWidth) * 100}% ${((y - tile.offsetTop - container.getBoundingClientRect().top) / photo.offsetHeight) * 100}%`,
      });
    };

    Array.prototype.forEach.call(tiles, (tile) => {
      tile.insertAdjacentHTML("beforeend", `<div class="${s.photo}" style="background-image: url(${tile.getAttribute("data-image")})"></div>`);
      // tile.insertAdjacentHTML("beforeend", `<div class="${s.text}"><span>${tile.getAttribute("data-scale")}x</span>ZOOM ON<br>HOVER</div>`);
      tile.insertAdjacentHTML("beforeend", `<div class="${s.text}">${tile.getAttribute("data-scale")}</div>`);

      tile.addEventListener("mouseover", onHover);
      tile.addEventListener("mouseout", onHover);
      tile.addEventListener("mousemove", onMove);
    });

    return () => {
      Array.prototype.forEach.call(tiles, (tile) => {
        tile.removeEventListener("mouseover", onHover);
        tile.removeEventListener("mouseout", onHover);
        tile.removeEventListener("mousemove", onMove);
      });
    }

  }, []);

  return (
    <div className={s.container}>
      <div className={s.tiles}>
        <div className={s.tile} data-scale="1.1" data-image={Image3}></div>
        <div className={s.tile} data-scale="1.6" data-image={Image2}></div>
        <div className={s.tile} data-scale="2.4" data-image={Image1}></div>
      </div>
    </div>
  );
};

export default ZoomAndPan;
