
import React, { useEffect, useRef } from "react";
import { Power4, TweenMax } from "gsap";
import { throttle } from "throttle-debounce";

import s from "./MousemoveFloating.module.scss";

const MousemoveFloatingAround = () => {

  const icons = useRef(null);

  useEffect(() => {

    const moveIcons = throttle(123, (event) => {
      const globalX = event.touches ? event.touches[0].clientX : event.clientX;
      const globalY = event.touches ? event.touches[0].clientY : event.clientY;
      const globalXPercentHalf = globalX / window.innerWidth - .5;
      const globalYPercentHalf = globalY / window.innerHeight - .5;

      icons.current.querySelectorAll(`.${s.icon}`).forEach(icon => {
        if (!icon) return;

        const iconX = icon.getBoundingClientRect().left + icon.getBoundingClientRect().width / 2;
        const iconY = icon.getBoundingClientRect().top + icon.getBoundingClientRect().height / 2;
        const distanceX = Math.hypot(globalX - iconX);
        const distanceY = Math.hypot(globalY - iconY);

        TweenMax.to(icon, 2, {
          ease: Power4.easeOut,
          x: globalXPercentHalf * 100 * -distanceX / (window.innerWidth / 6),
          y: globalYPercentHalf * 100 * -distanceY / (window.innerHeight / 6),
        });
      });
    });

    window.addEventListener("mousemove", moveIcons);

    return () => {
      window.removeEventListener("mousemove", moveIcons);
      moveIcons.cancel();
    };

  }, []);

  return (
    <div className={s.container}>
      <div className={s.icons} ref={icons}>
        {Array.from({ length: 150 }, (_, index) => (
          <div key={`icon-${index}`}>
            <div className={s.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MousemoveFloatingAround;
