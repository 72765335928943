
import React, { useEffect } from "react";
import classNames from "classnames";
import { TweenMax, TimelineMax, Sine, Linear } from "gsap";

import s from "./RocketFlyBy.module.scss";

const Rocket = () => {

  useEffect(() => {

    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const rocket = document.querySelector(`.${s.rocket}`);
    const space = document.querySelector(`.${s.space}`);
    const dots = 40;
    const rocketAnimation = new TimelineMax({ repeat: -1 });

    const createRocketAnimation = () => {
      rocketAnimation
        .timeScale(.5)
        .set(rocket, { x: (-window.innerWidth / 2 - 40), y: 0, rotation: 0, opacity: 1, scale: 1, transformOrigin: "0 0" })
        .to(rocket, 1, { x: 0, ease: Sine.easeOut }, .5)
        .set(rocket, { transformOrigin: "0 -50px" }, "-=.3")
        .to(rocket, 1, { rotation: -360, ease: Sine.easeIn }, "-=.3")
        .set(rocket, { transformOrigin: "0 -100px" })
        .to(rocket, .6, { rotation: -720, y: 50, ease: Linear.easeNone })
        .set(rocket, { transformOrigin: "0 -150px" })
        .to(rocket, .5, { rotation: -1080, y: 75, ease: Linear.easeNone })
        .to(rocket, .25, { x: (window.innerWidth / 2 + 100), ease: Linear.easeNone })
        .to(rocket, .5, {});
    };

    const createDotAnimation = () => {
      if (!window.location.pathname.match(/rocket-fly-by/g)) return; // temp fix for this function running even when component unmounted

      const dot = {
        el: document.createElement("div"),
        x: -5 - randomInt(1, 6) * 5,
        y: randomInt(-6, 3),
        duration: randomInt(3, 5) / 15,
        delay: randomInt(3, 5) / 15,
      };
  
      const animation = new TimelineMax({
        delay: dot.delay,
        onComplete: () => {
          createDotAnimation();
          createFixedDotAnimation(dot.el.getBoundingClientRect().left, dot.el.getBoundingClientRect().top);
          dot.el.remove();
        }
      });
    
      dot.el.classList.add(s.dot);
      rocket.appendChild(dot.el);
    
      animation
        .set(dot.el, { y: dot.y })
        .to(dot.el, dot.duration, { x: dot.x, ease: Linear.easeNone });
    };
    
    const createFixedDotAnimation = (x, y) => {
      const dot = {
        el: document.createElement("div"),
        x: -5 * randomInt(1, 4),
        y: randomInt(-5,5) * 3,
        duration: randomInt(1, 4) / 2,
      };
    
      const animation = new TimelineMax({
        onComplete: () => dot.el.remove(),
      });
    
      dot.el.classList.add(s.dot);
      dot.el.classList.add(s.dot____fixed);
      space.appendChild(dot.el);
    
      animation
        .set(dot.el, { top: y, left: x })
        .to(dot.el, dot.duration, { x: dot.x, y: dot.y, opacity: 0 });
    };
    
    const adjustWindowSize = () => {
      TweenMax.to(rocket, .5, {
        scale: 0,
        onComplete: () => {
          rocketAnimation.clear();
          createRocketAnimation();
        }
      });
    };

    createRocketAnimation();
    
    for (let i = 0; i < dots; i++) {
      createDotAnimation();
    }

    window.addEventListener("resize", adjustWindowSize);

    return () => {
      window.removeEventListener("resize", adjustWindowSize);
    }
    
  }, []);

  return (
    <div className={s.container}>
      <div className={s.rocket}>
        <div className={s.rocket__body}>
          <div className={s.rocket__window} />
          <div className={classNames(s.rocket__engine, s.rocket__engine____left)} />
          <div className={classNames(s.rocket__engine, s.rocket__engine____right)} />
        </div>
      </div>
      <div className={s.space} />
    </div>
  );
};

export default Rocket;
