
import React, { useEffect, useRef } from "react";
import { Power3, TweenMax } from "gsap";

import s from "./CrawlingLine.module.scss";

const CrawlingLine = () => {

  const menu1 = useRef(null);

  useEffect(() => {

    const menuItems = menu1.current.querySelectorAll("li");
    const line = menu1.current.querySelector("hr");
    
    const setActive = (event) => {
      Array.prototype.forEach.call(menuItems, (item) => item.classList.remove(s.active));
      event.target.classList.add(s.active);
      setLine(true);
    };
    
    const setLine = (animated) => {
      const item = menu1.current.querySelector(`.${s.active}`);
      const itemW = Math.ceil(item.getBoundingClientRect().width);
      const itemL = Math.ceil(item.offsetLeft);
    
      if (animated) {
        TweenMax.to(line, .5, {
          x: itemL,
          width: itemW,
          ease: Power3.easeInOut,
        });
      } else {
        TweenMax.set(line, {
          x: itemL,
          width: itemW,
        });
      }
    };

    const initLine = () => setLine(false);

    Array.prototype.forEach.call(menuItems, (item) => item.addEventListener("click", setActive));
    initLine();
    window.addEventListener("resize", initLine);

    return () => {
      window.removeEventListener("resize", initLine);
    }

  }, []);

  const menu2 = useRef(null);

  useEffect(() => {

    const menuItems = menu2.current.querySelectorAll("li");
    const line = menu2.current.querySelector("hr");
    let currentActive = 3;
    
    const setActive = (index) => {
      const direction = index > currentActive ? "r" : "l";
      currentActive = index;
      setLine(true, direction);
    };
    
    const setLine = (animated, direction) => {
      const item = menuItems[currentActive];
      const itemW = Math.ceil(item.getBoundingClientRect().width);
      const itemL = Math.ceil(item.offsetLeft);
      const itemR = Math.ceil(item.parentElement.getBoundingClientRect().width - itemW - itemL);
    
      if (animated) {
        if (direction == "r") {
          TweenMax.to(line, .35, { right: itemR, ease: Power3.easeInOut });
          TweenMax.to(line, .35, { left: itemL, ease: Power3.easeInOut, delay: .35 });
        } else {
          TweenMax.to(line, .35, { left: itemL, ease: Power3.easeInOut });
          TweenMax.to(line, .35, { right: itemR, ease: Power3.easeInOut, delay: .35 });
        }
      } else {
        TweenMax.set(line, { left: itemL, right: itemR });
      }
    };

    const initLine = () => setLine(false);

    Array.prototype.forEach.call(menuItems, (item, index) => item.addEventListener("click", () => setActive(index)));
    setActive(currentActive);
    initLine();
    window.addEventListener("resize", initLine);

    return () => {
      window.removeEventListener("resize", initLine);
    }

  }, []);

  return (
    <div className={s.container}>
      <div className={s.menu} ref={menu1}>
        <ul>
          <li className={s.active}>Lorem ipsum</li>
          <li>Dolor sit amet consecte</li>
          <li>Tur</li>
          <li>Adipis</li>
          <li>Maecenas</li>
          <li>Pe</li>
        </ul>
        <hr />
      </div>
      <div className={s.menu} ref={menu2}>
        <ul>
          <li>Lorem ipsum</li>
          <li>Dolor sit amet consecte</li>
          <li>Tur</li>
          <li>Adipis</li>
          <li>Maecenas</li>
          <li>Pe</li>
        </ul>
        <hr />
      </div>
    </div>
  );
};

export default CrawlingLine;
