
import React, { useContext, useEffect } from "react";
import classNames from "classnames";

import { PageContext } from "../../layout";

import s from "./ScrollLoop.module.scss";

const ScrollLoop = () => {

  const page = useContext(PageContext);

  useEffect(() => {
    
    const hi = document.querySelector(`.${s.hi}`);
    const txt = document.querySelector(`.${s.txt}`);

    const loop = () => {
      const winH = window.innerHeight;
      const txtH = txt.offsetHeight;
      const txtY = txt.getBoundingClientRect().top;
      
      if (-(txtY - winH) >= txtH) {
        window.scroll(0, 1);
      }
      
      if (window.scrollY > 0) {
        hi.classList.add(s.hi____hidden);
      } else {
        hi.classList.remove(s.hi____hidden);
      }
    };

    window.addEventListener("scroll", loop);

    return () => {
      window.removeEventListener("scroll", loop);
    }

  }, []);
  
  return (
    <div className={classNames(s.ui, { [s.ui____minimized]: page.isUiMinimized })}>
      <div className={s.hi}>Scroll down</div>
      <div className={s.txt}>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et velit in ligula porta gravida lobortis in dui. Vestibulum volutpat velit ligula, et dapibus sapien faucibus eu. Quisque semper vitae tortor quis consectetur.</p>
        <p>Curabitur vestibulum eu sem ut ullamcorper. Phasellus pharetra, nibh at hendrerit bibendum, arcu quam finibus urna, vel dignissim enim ante ut elit. Quisque rutrum vehicula commodo. Suspendisse cursus purus vel odio dapibus maximus. Curabitur bibendum eget orci et ultricies.</p>
        <p>Proin a elit sed ligula euismod tincidunt eget molestie sapien. Integer tempus augue eget urna maximus sollicitudin. Aliquam posuere facilisis metus, at elementum tellus posuere nec. In tristique ornare ipsum, vel auctor magna cursus et.</p>
      </div>
    </div>
  );
};

export default ScrollLoop;
