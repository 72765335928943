
import React, { useEffect } from "react";
import classNames from "classnames";

import s from "./ResponsiveInlineNavigation.module.scss";

const ResponsiveInlineNavigation = () => {

  const itemsToShow = [7, 5, 3, 2];
  const text = [
    "Lorem Ipsum",
    "Dolor Sit",
    "Amet",
    "Consectetur",
    "Adipiscing",
    "Elit",
    "Quisque",
    "Eget Mauris",
  ];

  useEffect(() => {

    const lists = document.querySelectorAll(`.${s.list}`);

    const toggleNavigation = () => {
      Array.prototype.forEach.call(lists, (list) => {
        const trigger = list.nextElementSibling;
        const listWidth = parseFloat(list.getAttribute('data-width'));
        const wrapperWidth = list.parentElement.getBoundingClientRect().width - 1;

        if (listWidth >= wrapperWidth) {
          list.classList.add(s.list____hidden);
          trigger.classList.remove(s.trigger____hidden);
        } else {
          list.classList.remove(s.list____hidden);
          list.classList.remove(s.list____active);
          trigger.classList.add(s.trigger____hidden);
          trigger.classList.remove(s.trigger____active);
        }
      });
    };

    // init

    Array.prototype.forEach.call(lists, (list) => {
      const trigger = list.nextElementSibling;
      
      trigger.addEventListener("click", () => {
        if (list.classList.contains(s.list____active)) {
          list.classList.remove(s.list____active);
          trigger.classList.remove(s.trigger____active);
        } else {
          list.classList.add(s.list____active);
          trigger.classList.add(s.trigger____active);
        }
      });

      list.setAttribute("data-width", list.getBoundingClientRect().width);
      setTimeout(toggleNavigation, 0); // faking window load event
      // for some reason this needs to be fired for each list
      // otherwise it gets wrong widths until window is resized
    });

    // toggleNavigation();
    // setTimeout(toggleNavigation, 0);   
    // document.fonts.ready.then(toggleNavigation);
    window.addEventListener("resize", toggleNavigation);

    return () => {
      window.removeEventListener("resize", toggleNavigation);
    }

  }, []);

  return (
    <div className={s.container}>
      <div>
        {itemsToShow.map((itemsToShow) => (
          <div className={s.row} key={`row-${itemsToShow}`}>
            <div className={s.col}>
              <a href="#">{text[0]}</a>
            </div>
            <div className={s.col}>
              <ul className={s.list}>
                {text.filter((item, index) => index > 0 && index <= itemsToShow).map((item, index) => (
                  <li key={`item-${index}`}>
                    <a href="#">{item}</a>
                  </li>
                ))}
              </ul>
              <a href="#" className={classNames(s.trigger, s.trigger____hidden)}>≡</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResponsiveInlineNavigation;
