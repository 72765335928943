
import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { Power4, TweenLite } from "gsap";

import { PageContext } from "../../layout";

import s from "./SmoothScroll.module.scss";

const SmoothScroll = () => {

  const page = useContext(PageContext);

  // window scroll
  useEffect(() => {

    const wrapper = document.querySelector(`.${s.wrapper____native}`);

    const handleResize = () => {
      TweenLite.set(wrapper.nextElementSibling, {
        height: wrapper.offsetHeight,
      });
    };

    const handleScroll = () => {
      TweenLite.to(wrapper, 1.2, {
        ease: Power4.easeOut,
        y: -window.pageYOffset,
      });
    };

    handleResize();
    
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    }

  }, []);

  // overflowy scroll
  useEffect(() => {

    const wrappers = document.querySelectorAll(`.${s.wrapper____overflowy}`);

    wrappers.forEach(target => {
      const wrapper = target;
      const content = wrapper.querySelector(`.${s.wrapper__content}`);
      const scroll = wrapper.querySelector(`.${s.wrapper__scroll}`);

      const handleResize = () => {
        TweenLite.set(scroll.firstElementChild, {
          height: content.offsetHeight,
        });
      };

      const handleScroll = () => {
        const y = scroll.firstElementChild.getBoundingClientRect().top - content.parentElement.getBoundingClientRect().top;

        TweenLite.to(content, 1.2, {
          ease: Power4.easeOut,
          onComplete: changeOrder,
          y,
        });

        setTimeout(changeOrder, 600);
      };

      const changeOrder = (event) => {
        const y = -(scroll.firstElementChild.getBoundingClientRect().top - content.parentElement.getBoundingClientRect().top);
        const yMax = Math.floor(content.getBoundingClientRect().height - content.parentElement.getBoundingClientRect().height);

        TweenLite.set(scroll, {
          zIndex: event ? 3 : 1,
          // backgroundColor: event ? "rgba(255, 0, 0, .3)" : "rgba(0, 255, 0, .3)",
        });

        // console.log(y, yMax);

        setTimeout(() => {
          if (y <= 0 || y >= yMax) {
            TweenLite.set(scroll, {
              zIndex: 1,
              // backgroundColor: "rgba(0, 0, 255, .3)",
            });
          }
        }, 60);
      };

      handleResize();
      scroll.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);

      content.addEventListener("DOMMouseScroll", changeOrder);
      content.addEventListener("mousewheel", changeOrder);
      content.addEventListener("touchmove", changeOrder);

      return () => {
        content.removeEventListener("DOMMouseScroll", changeOrder);
        content.removeEventListener("mousewheel", changeOrder);
        content.removeEventListener("touchmove", changeOrder);
        scroll.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      }
    });

  }, []);

  // small hack to resize the container-height-setter-thingy after ui minimization
  useEffect(() => {
    setTimeout(() => {
      const event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, true);
      window.dispatchEvent(event);
    }, 670);
  }, [page.isUiMinimized]);


  // useEffect(() => {
  //   const wrapper = document.querySelector(`.${s.test2}`);
  //   const content = document.querySelector(`.${s.test2__content}`);
  //   const text = document.querySelector(`.${s.test2__text}`);
  //   const scrollPos = {
  //     current: 0,
  //     max: text.getBoundingClientRect().height - content.getBoundingClientRect().height,
  //     min: 0,
  //     step: 15,
  //   };

  //   const test2Resize = () => {
  //     // TweenLite.set(heightCheck, {
  //     //   height: text.offsetHeight,
  //     // });
  //   };

  //   const test2Scroll = (e) => {
  //     // console.log("scroll", content.firstElementChild.getBoundingClientRect().top - content.parentElement.getBoundingClientRect().top);
  //     // console.log(wrapper.getBoundingClientRect().top, content.getBoundingClientRect().top, heightCheck.getBoundingClientRect().top);
  //     // TweenLite.to(text, 1.2, {
  //     //   ease: Power4.easeOut,
  //     //   // y: -500,
  //     //   y: heightCheck.getBoundingClientRect().top - content.getBoundingClientRect().top,
  //     // });
  //     const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

  //     scrollPos.current = delta > 0 ? scrollPos.current - scrollPos.step : scrollPos.current + scrollPos.step;
  //     scrollPos.current = scrollPos.current <= 0 ? scrollPos.min : scrollPos.current;
  //     scrollPos.current = scrollPos.current >= scrollPos.max ? scrollPos.max : scrollPos.current;

  //     console.log(e.wheelDelta);

  //     TweenLite.to(text, 1.2, {
  //       ease: Power4.easeOut,
  //       y: -scrollPos.current,
  //     });

  //     e.stopPropagation();
  //   };

  //   const test = () => {
  //     console.log(text.getBoundingClientRect().top)
  //   }

  //   test2Resize();
  //   window.addEventListener("resize", test2Resize);
  //   content.addEventListener("scroll", test2Scroll);

  //   content.addEventListener("mousewheel", test2Scroll);
  //   content.addEventListener("DOMMouseScroll", test2Scroll);

  // }, []);

  return (
    <div>
      <div className={s.container}>
        <div className={classNames(s.wrapper, s.wrapper____native)}>
          <p>Base window scroll. Works pretty much the same as native browser&#39;s scroll. There&#39;s no scroll hijack - wrapper with content is position: fixed; and window&#39;s scroll position is triggering translateY transform on the wrapper with some nice fluffy ease.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eleifend eros. Curabitur in lacinia sapien. Nullam ac nunc eget augue ultricies volutpat. Maecenas suscipit velit vel quam cursus, at congue magna lacinia. Nam mollis magna eu mauris congue, ut tempor mauris vulputate. Aliquam porttitor magna ullamcorper, maximus est vitae, ultrices lorem. Fusce convallis congue lacus ac mollis. Nullam elementum fringilla lorem dignissim viverra. Nulla facilisi. Ut pretium malesuada ante, pulvinar sodales nunc consequat in.</p>
          <p>Ut eu odio malesuada, porta justo vitae, facilisis ante. Cras velit odio, sodales aliquet pulvinar id, ullamcorper vel risus. Etiam cursus velit ex. Sed semper, lacus vel dapibus feugiat, quam odio efficitur dolor, vitae venenatis leo nisi vitae ipsum. Donec fringilla lacinia turpis, eget egestas odio malesuada ut. Praesent in nulla ornare, rutrum est eu, sollicitudin diam. Duis fermentum libero nec urna faucibus gravida. Fusce semper mauris sed enim elementum, non aliquam tellus iaculis. Maecenas lacus eros, sollicitudin at dapibus vitae, congue sed nisl. Nullam convallis, nulla quis finibus faucibus, arcu ante ornare metus, sit amet sollicitudin turpis risus nec ligula. Morbi auctor ligula et nisi vestibulum volutpat.</p>
          <p>Vivamus pretium at sapien at efficitur. Nullam interdum odio ac tristique euismod. Duis at consequat risus. Donec eu mattis felis. Vivamus purus nulla, laoreet in tincidunt auctor, dignissim quis libero. Nam ultrices, magna quis eleifend lobortis, elit nunc dignissim dolor, gravida consequat dolor tortor ut massa. Donec efficitur lobortis magna a luctus. Morbi dictum fringilla nunc, sit amet volutpat libero viverra vel. Nullam interdum sapien enim. Vivamus sit amet bibendum magna.</p>
          <p>In ultricies lacinia est et ultrices. Suspendisse ut rutrum odio. Donec eu condimentum erat, id dapibus mauris. Phasellus sed sollicitudin sem. Proin hendrerit aliquam pulvinar. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque rhoncus odio congue, malesuada augue quis, finibus erat. Suspendisse varius diam sem. Etiam quis fringilla magna. Nunc placerat rutrum accumsan. Vivamus quis consequat ligula.</p>
          <p>Cras facilisis, purus a pulvinar euismod, justo nibh venenatis nunc, ac interdum tortor ex iaculis lacus. Mauris bibendum dictum metus, ut rutrum orci lacinia quis. Maecenas sit amet libero sodales, congue magna vel, tristique sem. Nunc mi tortor, iaculis ut bibendum sit amet, porttitor nec dolor. Pellentesque id commodo ex. Nunc finibus enim sapien, sit amet laoreet turpis vulputate in. Praesent sit amet lacinia ex. Suspendisse sit amet sagittis purus, eu interdum dui. Nulla malesuada ornare rhoncus. Suspendisse nec nisi vel quam pretium tristique eget eget ligula. Etiam dignissim arcu erat, ut eleifend metus tempus eget. Quisque sagittis libero a fermentum laoreet. Etiam rutrum quam dictum nisi fermentum, et pretium tellus scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <div />
        <div className={classNames(s.wrapper, s.wrapper____overflowy)}>
          <div className={s.wrapper__content}>
            <p>Container with overflow: auto; with few code tricks necessary to make it work properly - switching z-indexes between the wrap setting its&#39; height & content based on events that are being triggered at the moment.</p>
            <p>Cras facilisis, purus a pulvinar euismod, justo nibh venenatis nunc, ac interdum tortor ex iaculis lacus. Mauris bibendum dictum metus, ut rutrum orci lacinia quis. Maecenas sit amet libero sodales, congue magna vel, tristique sem. Nunc mi tortor, iaculis ut bibendum sit amet, porttitor nec dolor. Pellentesque id commodo ex. Nunc finibus enim sapien, sit amet laoreet turpis vulputate in. Praesent sit amet lacinia ex. Suspendisse sit amet sagittis purus, eu interdum dui. Nulla malesuada ornare rhoncus. Suspendisse nec nisi vel quam pretium tristique eget eget ligula. Etiam dignissim arcu erat, ut eleifend metus tempus eget. Quisque sagittis libero a fermentum laoreet. Etiam rutrum quam dictum nisi fermentum, et pretium tellus scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <p>In ultricies lacinia est et ultrices. Suspendisse ut rutrum odio. Donec eu condimentum erat, id dapibus mauris. Phasellus sed sollicitudin sem. Proin hendrerit aliquam pulvinar. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque rhoncus odio congue, malesuada augue quis, finibus erat. Suspendisse varius diam sem. Etiam quis fringilla magna. Nunc placerat rutrum accumsan. Vivamus quis consequat ligula.</p>
            <p>Ut eu odio malesuada, porta justo vitae, facilisis ante. Cras velit odio, sodales aliquet pulvinar id, ullamcorper vel risus. Etiam cursus velit ex. Sed semper, lacus vel dapibus feugiat, quam odio efficitur dolor, vitae venenatis leo nisi vitae ipsum. Donec fringilla lacinia turpis, eget egestas odio malesuada ut. Praesent in nulla ornare, rutrum est eu, sollicitudin diam. Duis fermentum libero nec urna faucibus gravida. Fusce semper mauris sed enim elementum, non aliquam tellus iaculis. Maecenas lacus eros, sollicitudin at dapibus vitae, congue sed nisl. Nullam convallis, nulla quis finibus faucibus, arcu ante ornare metus, sit amet sollicitudin turpis risus nec ligula. Morbi auctor ligula et nisi vestibulum volutpat.</p>
            <p>Vivamus pretium at sapien at efficitur. Nullam interdum odio ac tristique euismod. Duis at consequat risus. Donec eu mattis felis. Vivamus purus nulla, laoreet in tincidunt auctor, dignissim quis libero. Nam ultrices, magna quis eleifend lobortis, elit nunc dignissim dolor, gravida consequat dolor tortor ut massa. Donec efficitur lobortis magna a luctus. Morbi dictum fringilla nunc, sit amet volutpat libero viverra vel. Nullam interdum sapien enim. Vivamus sit amet bibendum magna.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eleifend eros. Curabitur in lacinia sapien. Nullam ac nunc eget augue ultricies volutpat. Maecenas suscipit velit vel quam cursus, at congue magna lacinia. Nam mollis magna eu mauris congue, ut tempor mauris vulputate. Aliquam porttitor magna ullamcorper, maximus est vitae, ultrices lorem. Fusce convallis congue lacus ac mollis. Nullam elementum fringilla lorem dignissim viverra. Nulla facilisi. Ut pretium malesuada ante, pulvinar sodales nunc consequat in.</p>
          </div>
          <div className={s.wrapper__scroll}>
            <div />
          </div>
        </div>
        <div className={classNames(s.wrapper, s.wrapper____overflowy, s.wrapper____overflowy_2)}>
          <div className={s.wrapper__content}>
            <p>Same as above, but with a hidden scrollbar.</p>
            <p>In ultricies lacinia est et ultrices. Suspendisse ut rutrum odio. Donec eu condimentum erat, id dapibus mauris. Phasellus sed sollicitudin sem. Proin hendrerit aliquam pulvinar. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque rhoncus odio congue, malesuada augue quis, finibus erat. Suspendisse varius diam sem. Etiam quis fringilla magna. Nunc placerat rutrum accumsan. Vivamus quis consequat ligula.</p>
            <p>Vivamus pretium at sapien at efficitur. Nullam interdum odio ac tristique euismod. Duis at consequat risus. Donec eu mattis felis. Vivamus purus nulla, laoreet in tincidunt auctor, dignissim quis libero. Nam ultrices, magna quis eleifend lobortis, elit nunc dignissim dolor, gravida consequat dolor tortor ut massa. Donec efficitur lobortis magna a luctus. Morbi dictum fringilla nunc, sit amet volutpat libero viverra vel. Nullam interdum sapien enim. Vivamus sit amet bibendum magna.</p>
            <p>Ut eu odio malesuada, porta justo vitae, facilisis ante. Cras velit odio, sodales aliquet pulvinar id, ullamcorper vel risus. Etiam cursus velit ex. Sed semper, lacus vel dapibus feugiat, quam odio efficitur dolor, vitae venenatis leo nisi vitae ipsum. Donec fringilla lacinia turpis, eget egestas odio malesuada ut. Praesent in nulla ornare, rutrum est eu, sollicitudin diam. Duis fermentum libero nec urna faucibus gravida. Fusce semper mauris sed enim elementum, non aliquam tellus iaculis. Maecenas lacus eros, sollicitudin at dapibus vitae, congue sed nisl. Nullam convallis, nulla quis finibus faucibus, arcu ante ornare metus, sit amet sollicitudin turpis risus nec ligula. Morbi auctor ligula et nisi vestibulum volutpat.</p>
            <p>Cras facilisis, purus a pulvinar euismod, justo nibh venenatis nunc, ac interdum tortor ex iaculis lacus. Mauris bibendum dictum metus, ut rutrum orci lacinia quis. Maecenas sit amet libero sodales, congue magna vel, tristique sem. Nunc mi tortor, iaculis ut bibendum sit amet, porttitor nec dolor. Pellentesque id commodo ex. Nunc finibus enim sapien, sit amet laoreet turpis vulputate in. Praesent sit amet lacinia ex. Suspendisse sit amet sagittis purus, eu interdum dui. Nulla malesuada ornare rhoncus. Suspendisse nec nisi vel quam pretium tristique eget eget ligula. Etiam dignissim arcu erat, ut eleifend metus tempus eget. Quisque sagittis libero a fermentum laoreet. Etiam rutrum quam dictum nisi fermentum, et pretium tellus scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eleifend eros. Curabitur in lacinia sapien. Nullam ac nunc eget augue ultricies volutpat. Maecenas suscipit velit vel quam cursus, at congue magna lacinia. Nam mollis magna eu mauris congue, ut tempor mauris vulputate. Aliquam porttitor magna ullamcorper, maximus est vitae, ultrices lorem. Fusce convallis congue lacus ac mollis. Nullam elementum fringilla lorem dignissim viverra. Nulla facilisi. Ut pretium malesuada ante, pulvinar sodales nunc consequat in.</p>
          </div>
          <div className={s.wrapper__scroll}>
            <div />
          </div>
        </div>
        {/* <div className={s.test2}>
          <div className={s.test2__content}>
            <div className={s.test2__text}>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eleifend eros. Curabitur in lacinia sapien. Nullam ac nunc eget augue ultricies volutpat. Maecenas suscipit velit vel quam cursus, at congue magna lacinia. Nam mollis magna eu mauris congue, ut tempor mauris vulputate. Aliquam porttitor magna ullamcorper, maximus est vitae, ultrices lorem. Fusce convallis congue lacus ac mollis. Nullam elementum fringilla lorem dignissim viverra. Nulla facilisi. Ut pretium malesuada ante, pulvinar sodales nunc consequat in.</p>
              <p>Ut eu odio malesuada, porta justo vitae, facilisis ante. Cras velit odio, sodales aliquet pulvinar id, ullamcorper vel risus. Etiam cursus velit ex. Sed semper, lacus vel dapibus feugiat, quam odio efficitur dolor, vitae venenatis leo nisi vitae ipsum. Donec fringilla lacinia turpis, eget egestas odio malesuada ut. Praesent in nulla ornare, rutrum est eu, sollicitudin diam. Duis fermentum libero nec urna faucibus gravida. Fusce semper mauris sed enim elementum, non aliquam tellus iaculis. Maecenas lacus eros, sollicitudin at dapibus vitae, congue sed nisl. Nullam convallis, nulla quis finibus faucibus, arcu ante ornare metus, sit amet sollicitudin turpis risus nec ligula. Morbi auctor ligula et nisi vestibulum volutpat.</p>
              <p>Vivamus pretium at sapien at efficitur. Nullam interdum odio ac tristique euismod. Duis at consequat risus. Donec eu mattis felis. Vivamus purus nulla, laoreet in tincidunt auctor, dignissim quis libero. Nam ultrices, magna quis eleifend lobortis, elit nunc dignissim dolor, gravida consequat dolor tortor ut massa. Donec efficitur lobortis magna a luctus. Morbi dictum fringilla nunc, sit amet volutpat libero viverra vel. Nullam interdum sapien enim. Vivamus sit amet bibendum magna.</p>
              <p>In ultricies lacinia est et ultrices. Suspendisse ut rutrum odio. Donec eu condimentum erat, id dapibus mauris. Phasellus sed sollicitudin sem. Proin hendrerit aliquam pulvinar. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque rhoncus odio congue, malesuada augue quis, finibus erat. Suspendisse varius diam sem. Etiam quis fringilla magna. Nunc placerat rutrum accumsan. Vivamus quis consequat ligula.</p>
              <p>Cras facilisis, purus a pulvinar euismod, justo nibh venenatis nunc, ac interdum tortor ex iaculis lacus. Mauris bibendum dictum metus, ut rutrum orci lacinia quis. Maecenas sit amet libero sodales, congue magna vel, tristique sem. Nunc mi tortor, iaculis ut bibendum sit amet, porttitor nec dolor. Pellentesque id commodo ex. Nunc finibus enim sapien, sit amet laoreet turpis vulputate in. Praesent sit amet lacinia ex. Suspendisse sit amet sagittis purus, eu interdum dui. Nulla malesuada ornare rhoncus. Suspendisse nec nisi vel quam pretium tristique eget eget ligula. Etiam dignissim arcu erat, ut eleifend metus tempus eget. Quisque sagittis libero a fermentum laoreet. Etiam rutrum quam dictum nisi fermentum, et pretium tellus scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <p>END</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SmoothScroll;
