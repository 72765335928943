
import React, { useEffect } from "react";

import s from "./TsunamiNavigation.module.scss";

const TsunamiNavigation = () => {
  
  const items = [
    "lorem ipsum",
    "dolor sit amet",
    "consectetur",
    "adipiscing elit",
    "sed rhoncus",
    "a leo eu",
    "elementum",
    "quisque id",
    "condimentum",
    "augue vel",
    "venenatis nunc",
    "nulla scelerisque",
    // "dui in ultricies",
    // "tristique lectus",
    // "ipsum blandit",
  ];

  useEffect(() => {

    const links = document.querySelectorAll(`.${s.container} a`);

    const onHover = (event) => {
      const link = {
        prev3: (event.currentTarget.parentElement.previousSibling && event.currentTarget.parentElement.previousSibling.previousSibling) ? event.currentTarget.parentElement.previousSibling.previousSibling.firstChild : null,
        prev2: event.currentTarget.parentElement.previousSibling ? event.currentTarget.parentElement.previousSibling.firstChild : null,
        current: event.currentTarget,
        next2: event.currentTarget.parentElement.nextSibling ? event.currentTarget.parentElement.nextSibling.firstChild : null,
        next3: (event.currentTarget.parentElement.nextSibling && event.currentTarget.parentElement.nextSibling.nextSibling) ? event.currentTarget.parentElement.nextSibling.nextSibling.firstChild : null,
      };

      if (event.type === "mouseover") {
        if (link.prev3) link.prev3.classList.add(s.h3);
        if (link.prev2) link.prev2.classList.add(s.h2);
        link.current.classList.add(s.h1);
        if (link.next2) link.next2.classList.add(s.h2);
        if (link.next3) link.next3.classList.add(s.h3);
      } else if (event.type === "mouseout") {
        if (link.prev3) link.prev3.classList.remove(s.h3);
        if (link.prev2) link.prev2.classList.remove(s.h2);
        link.current.classList.remove(s.h1);
        if (link.next2) link.next2.classList.remove(s.h2);
        if (link.next3) link.next3.classList.remove(s.h3);
      }
    };

    Array.prototype.forEach.call(links, (link) => {
      link.addEventListener("mouseover", onHover);
      link.addEventListener("mouseout", onHover);
    });

    return () => {
      Array.prototype.forEach.call(links, (link) => {
        link.removeEventListener("mouseover", onHover);
        link.removeEventListener("mouseout", onHover);
      });
    }

  }, []);

  return (
    <div className={s.container}>
      <div>
        <ul>
          {items.map((item, index) => (
            <li key={`item-${index}`}>
              <a href="#">{item}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TsunamiNavigation;
