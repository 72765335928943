
import React from "react";

import s from "./Skapalon.module.scss";

const Skapalon = () => (
  <div className={s.container}>
    <div className={s.logo}>
      <div>
        <div>
          <div />
        </div>
        <div>
          <div />
        </div>
      </div>
    </div>
  </div>
);

export default Skapalon;
