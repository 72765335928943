
import React, { useEffect } from "react";
import { Expo, TimelineMax, TweenMax } from "gsap";

import s from "./ChevronToBox.module.scss";

const ChevronToBox = () => {

  useEffect(() => {

    const el = {
      box: document.querySelector(`.${s.chevvvy__box}`),
      boxWrap: document.querySelector(`.${s.chevvvy__box_wrap}`),
      link:  document.querySelector(`.${s.chevvvy__link}`),
      text: document.querySelector(`.${s.chevvvy__text}`),
      textWrap: document.querySelector(`.${s.chevvvy__text_wrap}`),
    };

    const animation = {
      flyIn: new TimelineMax(),
      flyOut: new TimelineMax(),
    };

    let readyToClose = false;
    
    const initAnimations = () => {

      TweenMax.set(el.box, {
        scale: .0425,
        clipPath: "polygon(0% 0%, 100% 50%, 100% 50%, 0% 100%, 15% 50%)",
      });

      TweenMax.set(el.textWrap, {
        opacity: 0,
      });
    
      animation.flyIn
        .pause()
        .to(el.boxWrap, 1, {
          rotation: -360,
          ease: Expo.easeInOut,
          clearProps: "all", 
          onComplete: () => readyToClose = true,
        })
        .to(el.link, .3, {
          opacity: 0,
          pointerEvents: "none",
        }, 0)
        .to(el.box, .5, {
          scale: 1
        }, .3)
        .to(el.box, .3, {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%)",
        }, .5)
        .to(el.textWrap, 1, {
          opacity: 1
        }, .5);
    
      animation.flyOut
        .pause()
        .to(el.textWrap, .5, {
          opacity: 0,
        })
        .to(el.boxWrap, 1, {
          rotation: -360,
          ease: Expo.easeInOut,
          clearProps: "all",
          onStart: () => readyToClose = false,
          onComplete: () => el.textWrap.scrollTop = 0,
        }, 0)
        .to(el.link, 1, {
          opacity: 1,
          clearProps: "all",
        }, .7)
        .to(el.box, .5, {
          scale: .0425,
          clipPath: "polygon(0% 0%, 100% 50%, 100% 50%, 0% 100%, 15% 50%)",
          ease: Expo.easeInOut
        }, .25);
    };
    
    const showTheBox = (event) => {
      event.stopPropagation();
      animation.flyIn.seek(0).play();
    };
    
    const hideTheBox = (event) => {
      if (event.target === el.text || event.target.parentElement === el.text) return;
      if (readyToClose) animation.flyOut.seek(0).play();
    };
          
    // init
    initAnimations();
    el.link.addEventListener("click", showTheBox);
    document.addEventListener("click", hideTheBox);

    return () => {
      el.link.removeEventListener("click", showTheBox);
      document.removeEventListener("click", hideTheBox);
    };

  }, []);

  return (
    <div className={s.container}>
      <div className={s.chevvvy}>
        <div className={s.chevvvy__link}>Read more</div>
        <div className={s.chevvvy__box_wrap}>
          <div className={s.chevvvy__box}>
            <div className={s.chevvvy__text_wrap}>
              <div className={s.chevvvy__text}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur et nisi a massa dictum placerat nec et dui. Aenean id commodo eros, convallis tempor quam. Mauris aliquet, nisi elementum interdum pretium, tellus odio placerat augue, quis posuere risus nisi id ipsum. Fusce tincidunt nunc et aliquet tincidunt. Morbi egestas efficitur mauris, id dignissim.</p>
                <p>Lectus facilisis maximus. Cras finibus quam eget eros aliquet rutrum. Donec eget eros lobortis, laoreet velit sit amet, tristique ex. Nulla facilisi. Nullam euismod mauris sed nisi placerat, eget ornare ex fringilla. Morbi vehicula eros quis lacus fringilla cursus. Etiam congue id orci vitae interdum. Phasellus vulputate ipsum ut enim laoreet sollicitudin.</p>
                <p>Etiam non dolor egestas, aliquam arcu eu, eleifend metus. Vivamus auctor porta viverra. Integer finibus est ut lobortis auctor. Cras vitae nisl a massa pulvinar venenatis accumsan vel nisi.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChevronToBox;
