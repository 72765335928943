
import React from "react";

import s from "./Siteception.module.scss";

const Siteception = () => (
  <div className={s.container}>
    <div className={s.back}>
      <iframe src="/siteception/" />
      <div className={s.back__image} />
    </div>
    {/* <div className={s.filler} /> */}
  </div>
);

export default Siteception;
