
import React, { useEffect } from "react";

import s from "./GradientBackground.module.scss";

const GradientBackground = () => {

  useEffect(() => {
    
    const background = document.querySelector(`.${s.background}`);
    const gradient = document.querySelector(`.${s.gradient}`);

    const updateGradient = () => {
      const pos = window.scrollY / (background.offsetHeight - window.innerHeight) * 100;
      gradient.style.transform = `translate3d(-${pos / 2}%, 0, 0)`;
    };

    updateGradient();

    window.addEventListener("resize", updateGradient);
    window.addEventListener("scroll", updateGradient);

    return () => {
      window.removeEventListener("resize", updateGradient);
      window.removeEventListener("scroll", updateGradient);
    };

  }, []);
  
  return (
    <div className={s.background}>
      <div className={s.gradient} />
    </div>
  );
};

export default GradientBackground;
