
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Elastic, Power3, TweenMax } from "gsap";

import noUiSlider from "nouislider";

import s from "./Configurator.module.scss";
import "./nouislider.scss";
// import ss from "./Slider.module.scss";
// import ss from "./nouislider.module.scss";

// schedule:


const Configurator = () => {

  const baseDimensions = { min: 1000, max: 4400 };
  const baseDimensionsMiddle = (baseDimensions.max - baseDimensions.min) / 2 + baseDimensions.min;

  const currentStep = 1;
  const [step, setStep] = useState(currentStep);
  const [available, setAvailable] = useState(currentStep);

  const [productPrice, setProductPrice] = useState(0);
  const [productProps, setProductProps] = useState({ type: null, color: null });
  const [productWidth, setProductWidth] = useState(null);
  const [productHeight, setProductHeight] = useState(null);

  // calculating price estimate
  useEffect(() => {
    const { type, color } = productProps;
    const basePrice = !type ? 0 : 1000;

    const el = {
      price: document.querySelector(`.${s.result} p + p`),
    };
    
    const priceMod = {
      type: type === "wood" || type === "plastic" ? 1 : type === "aluminium" ? 1.2 : 2,
      color: !color || color === "white" ? 0 : color ==="black" || color === "grey" ? 90 : 200,
      dimensions: !productWidth || !productHeight ? 0 : ((productWidth * productHeight) - Math.pow(baseDimensionsMiddle, 2)) / 20000000,
    };
    
    const estimatedPrice = basePrice * priceMod.type + priceMod.color + basePrice * priceMod.dimensions;
    const currentPrice = { amount: productPrice };

    TweenMax.to(currentPrice, .35, {
      amount: estimatedPrice,
      roundProps: "amount",
      ease: Power3.easeOut,
      onUpdate: () => setProductPrice(currentPrice.amount),
    });

    TweenMax.to(el.price, .1, { scaleY: .8 });
    TweenMax.to(el.price, 1, {
      clearProps: "all",
      delay: .1,
      ease: Elastic.easeOut,
      scaleY: 1,
    });

  }, [productProps, productWidth, productHeight]);

  // handling dimensions slider
  useEffect(() => {

    const el = {
      widthSlider: document.querySelector(`.${s.slider____width}`),
      heightSlider: document.querySelector(`.${s.slider____height}`),
    };    

    if (step === 3) {
      if (el.widthSlider.noUiSlider) return; // avoiding hot-reload errors

      noUiSlider.create(el.widthSlider, {
        range: {
          min: baseDimensions.min,
          max: baseDimensions.max,
        },
        start: productWidth ? productWidth : baseDimensionsMiddle,
        step: 100,
      });

      noUiSlider.create(el.heightSlider, {
        direction: "rtl",
        orientation: "vertical",
        range: {
          min: baseDimensions.min,
          max: baseDimensions.max,
        },
        start: productHeight ? productHeight : baseDimensionsMiddle,
        step: 100,
      });

      el.widthSlider.noUiSlider.on("update", value => setProductWidth(parseFloat(value[0].replace('.', '') / 100)));
      el.heightSlider.noUiSlider.on("update", value => setProductHeight(parseFloat(value[0].replace('.', '') / 100)));
    }

    return () => {
      if (step === 3) {
        el.widthSlider.setAttribute("disabled", true);
        el.heightSlider.setAttribute("disabled", true);
      }
    }

  }, [step]);

  useEffect(() => {
    if (step === 3) {
      TweenMax.to(document.querySelector(`.${s.sliders__window}`), .5, {
        scaleX: productWidth / 2700,
        scaleY: productHeight / 2700,
        ease: Power3.easeOut,
      });
    }
  }, [productWidth, productHeight]);

  // calculating & displaying total time spent on project
  useEffect(() => {
    let total = 0;
    const time = {
      10.10: 1,
      15.10: 1.5,
      16.10: 1,
      17.10: 0.5,
      21.10: 0.5,
      22.10: 1,
      23.10: 1,
      24.10: 1,
      1.11: 1.5,
    };
    
    Object.keys(time).map(key => total += time[key]);

    document.querySelector(`.${s.timespent} span`).innerHTML = total;
  }, []);

  const render = {

    triggers: [
      "Type",
      "Color",
      "Dimensions",
      "Glass",
      "Handles",
      "Extras",
      "Delivery",
    ],

    // TODO
    // eslint-disable-next-line
    trigger: (props) => {
      // eslint-disable-next-line
      const { index, name } = props;
      return (
        <li
          className={classNames(s.head__trigger, {
            [s.head__trigger____disabled]: available < index,
            [s.head__trigger____active]: step === index,
          })}
          key={`trigger-${index}`}
          onClick={() => setStep(index)}
        >
          {name}
        </li>
      );
    },
  };

  return (
    <div className={s.container}>
      <div className={s.app}>
        <div className={s.head}>
          <h1 onClick={() => setStep(0)}>Cnfgrtr</h1>
          <p>Select options.</p>
          <ul className={s.head__triggers}>
            {render.triggers.map((name, index) => render.trigger({ index: index + 1, name }))}
          </ul>
          <div className={s.window__wrap}>
            <div className={s.window}>
              <div className={classNames(s.window__side, s.window__side____left)} />
              <div className={classNames(s.window__side, s.window__side____right)} />
            </div>
          </div>
        </div>
        <div className={s.body}>
          {available >= 1 && step === 1 && ( // product type
            <div>
              <select
                onChange={(event) => {
                  setProductProps({ ...productProps, type: event.target.value });
                  if (available < 2) setAvailable(2);
                }}
                value={productProps.type ? productProps.type : "undefined"}
              >
                <option value="undefined" disabled hidden>choose type</option>
                <option value="aluminium">aluminium</option>
                <option value="plastic">plastic</option>
                <option value="steel">steel</option>
                <option value="stone">stone</option>
                <option value="wood">wood</option>
              </select>
            </div>
          )}
          {available >= 2 && step === 2 && ( // product color
            <div>
              <select
                onChange={(event) => {
                  setProductProps({ ...productProps, color: event.target.value });
                  if (available < 3) setAvailable(3);
                }}
                value={productProps.color ? productProps.color : "undefined"}
              >
                <option value="undefined" disabled hidden>choose color</option>
                <option value="black">black</option>
                <option value="grey">grey</option>
                <option value="white">white</option>
                <option value="red">red</option>
                <option value="green">green</option>
              </select>
            </div>
          )}
          {available >= 3 && step === 3 && ( // product dimensions
            <div>
              <div className={s.sliders}>
                <div className={s.sliders__window_wrap}>
                  <div className={s.sliders__window} />
                </div>
                <div className={classNames(s.slider, s.slider____width)}>
                  <div className={classNames(s.slider__value, s.slider__value____width)}>
                    w: <span>{!productWidth ? "..." : productWidth}</span>
                  </div>
                </div>
                <div className={classNames(s.slider, s.slider____height)}>
                  <div className={classNames(s.slider__value, s.slider__value____height)}>
                    h: <span>{!productHeight ? "..." : productHeight}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={s.result}>
            <p>Your selection:</p>
            <ul>
              <li className={!productProps.type ? s.result____disabled : undefined}>
                <span>Type</span>
                {!productProps.type ? "..." : productProps.type}
              </li>
              <li className={!productProps.color ? s.result____disabled : undefined}>
                <span>Color</span>
                {!productProps.color ? "..." : productProps.color}
              </li>
              <li className={!productWidth && !productHeight ? s.result____disabled : undefined}>
                <span>Dimensions</span>
                {!productWidth ? "..." : productWidth}x{!productHeight ? "..." : productHeight}
              </li>
            </ul>
            <br />
            <p>Estimated price:</p>
            <p>{productPrice}</p>
            <br />
            <p className={s.timespent}>Time spent on project: <span>...</span>h</p>
            {/* <li>[results based on user's choices]</li>
            <li>[estimated price]</li>
            <li>[estimated discounted price]</li>
            <li>[price value + comparisions]</li> */}
          </div>
      </div>
    </div>
  );
};

export default Configurator;
