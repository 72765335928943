
import React, { useEffect } from "react";
import { Power3, TweenLite } from "gsap";

import Mp4 from "../../images/macpro.mp4";

import s from "./VideoScroll.module.scss";

const VideoScroll = () => {

  useEffect(() => {

    const el = {
      container: document.querySelector(`.${s.container}`),
      video: document.querySelector(`.${s.video}`),
    };

    const handleVideoLoop = () => {
      
      el.video.addEventListener("timeupdate", () => {
        if (!el.video.paused && el.video.currentTime > .8) {
          el.video.currentTime = 0;
        }
      });
    };

    const handleVideoScroll = () => {
      if (window.pageYOffset <= 30) {
        TweenLite.to(el.video, .5, {
          ease: Power3.easeOut,
          currentTime: 0,
          onComplete: () => el.video.play(),
          overwrite: true,
        });
      } else {
        el.video.pause();
        TweenLite.to(el.video, 1, {
          ease: Power3.easeOut,
          currentTime: el.video.duration * (window.pageYOffset / (el.container.offsetHeight - window.innerHeight)),
          overwrite: "preexisting",
        });
      }
    };
    handleVideoLoop();
    
    window.addEventListener("scroll", handleVideoScroll);
    
    return () => {
      window.removeEventListener("scroll", handleVideoScroll);
    }

  }, []);

  return (
    <div>
      <div className={s.container}>
        <div>
          <video className={s.video} autoPlay muted>
            <source src={Mp4} type="video/mp4" />
          </video>
        </div>
        <div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet eleifend eros.</p>
          <p>Curabitur in lacinia sapien. Nullam ac nunc eget augue ultricies volutpat. Maecenas suscipit velit vel quam cursus, at congue magna lacinia.</p>
          <p>Nam mollis magna eu mauris congue, ut tempor mauris vulputate. Aliquam porttitor magna ullamcorper, maximus est vitae, ultrices lorem. Fusce convallis congue lacus ac mollis.</p>
          <p>Nullam elementum fringilla lorem dignissim viverra. Nulla facilisi. Ut pretium malesuada ante, pulvinar sodales nunc consequat in.</p>
          <p>Ut eu odio malesuada, porta justo vitae, facilisis ante. Cras velit odio, sodales aliquet pulvinar id, ullamcorper vel risus. Etiam cursus velit ex.</p>
          <p>Sed semper, lacus vel dapibus feugiat, quam odio efficitur dolor, vitae venenatis leo nisi vitae ipsum. Donec fringilla lacinia turpis, eget egestas odio malesuada ut.</p>
          <p>Praesent in nulla ornare, rutrum est eu, sollicitudin diam. Duis fermentum libero nec urna faucibus gravida. Fusce semper mauris sed enim elementum, non aliquam tellus iaculis.</p>
          <p>Maecenas lacus eros, sollicitudin at dapibus vitae, congue sed nisl. Nullam convallis, nulla quis finibus faucibus, arcu ante ornare metus, sit amet sollicitudin turpis risus nec ligula. Morbi auctor ligula et nisi vestibulum volutpat.</p>
          <p>Vivamus pretium at sapien at efficitur. Nullam interdum odio ac tristique euismod. Duis at consequat risus. Donec eu mattis felis. Vivamus purus nulla, laoreet in tincidunt auctor, dignissim quis libero. Nam ultrices, magna quis eleifend lobortis, elit nunc dignissim dolor, gravida consequat dolor tortor ut massa. Donec efficitur lobortis magna a luctus. Morbi dictum fringilla nunc, sit amet volutpat libero viverra vel. Nullam interdum sapien enim. Vivamus sit amet bibendum magna.</p>
          <p>In ultricies lacinia est et ultrices. Suspendisse ut rutrum odio. Donec eu condimentum erat, id dapibus mauris. Phasellus sed sollicitudin sem. Proin hendrerit aliquam pulvinar. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque rhoncus odio congue, malesuada augue quis, finibus erat. Suspendisse varius diam sem. Etiam quis fringilla magna. Nunc placerat rutrum accumsan. Vivamus quis consequat ligula.</p>
          <p>Cras facilisis, purus a pulvinar euismod, justo nibh venenatis nunc, ac interdum tortor ex iaculis lacus. Mauris bibendum dictum metus, ut rutrum orci lacinia quis. Maecenas sit amet libero sodales, congue magna vel, tristique sem. Nunc mi tortor, iaculis ut bibendum sit amet, porttitor nec dolor. Pellentesque id commodo ex. Nunc finibus enim sapien, sit amet laoreet turpis vulputate in. Praesent sit amet lacinia ex. Suspendisse sit amet sagittis purus, eu interdum dui. Nulla malesuada ornare rhoncus. Suspendisse nec nisi vel quam pretium tristique eget eget ligula. Etiam dignissim arcu erat, ut eleifend metus tempus eget. Quisque sagittis libero a fermentum laoreet. Etiam rutrum quam dictum nisi fermentum, et pretium tellus scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
    </div>
  );
};

export default VideoScroll;
