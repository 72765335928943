
import React, { useEffect } from "react";
import { TweenMax } from "gsap";

import Image1 from "../../images/sjova-1.jpg";
import Image2 from "../../images/sjova-2.jpg";
import Image3 from "../../images/sjova-3.jpg";
import Image4 from "../../images/sjova-4.jpg";
import Image5 from "../../images/sjova-5.jpg";
import Image6 from "../../images/sjova-6.jpg";

import s from "./ImageParallax.module.scss";

const ImageParallax = () => {

  useEffect(() => {

    const images = document.querySelectorAll(`.${s.image}`);
    const parallaxOffset = 67; // this offsets the image by this amount of pixels in both directions

    const parallaxImages = () => {
      const windowHeight = window.innerHeight;
      // const scrollTop = -document.body.getBoundingClientRect().top;

      images.forEach((image) => {
        const imageHeight = image.getBoundingClientRect().height;
        const imageTop = image.getBoundingClientRect().top;
        const direction = image.firstElementChild.getAttribute("data-direction"); // 1 moves image up, -1 moves it down
        const heightOffset = 1 - imageHeight / window.innerHeight; // height modifier to avoid image going out of bounds

        // image is within the viewport
        if (imageTop < windowHeight && -imageTop < imageHeight) {
          const position = ((imageTop + imageHeight / 2) / windowHeight); // 1 (100%) at bottom of the screen, 0 (0%)at the top
          const positionCentered = (position - .5) * 2; // 1 (100%) at the bottom, -1 (-100%) at top, 0 in the middle

          TweenMax.set(image.firstElementChild, {
            y: positionCentered * parallaxOffset * direction * heightOffset,
            top: -parallaxOffset,
            bottom: -parallaxOffset,
          });
        }
      });
    };

    parallaxImages();

    window.addEventListener("resize", parallaxImages);
    window.addEventListener("scroll", parallaxImages);

    return () => {
      window.removeEventListener("resize", parallaxImages);
      window.removeEventListener("scroll", parallaxImages);
    }

  }, []);

  return (
    <div className={s.container}>
      <div className={s.images}>
        <div className={s.image}>
          <div
            className={s.image__parallax}
            data-direction={1}
            style={{ backgroundImage: `url(${Image1})` }}
          />
        </div>
        <div className={s.image}>
          <div
            className={s.image__parallax}
            data-direction={-1}
            style={{ backgroundImage: `url(${Image2})` }}
          />
        </div>
      </div>
      <div className={s.images}>
        <div className={s.image}>
          <div
            className={s.image__parallax}
            data-direction={1}
            style={{ backgroundImage: `url(${Image3})` }}
          />
        </div>
        <div className={s.image}>
          <div
            className={s.image__parallax}
            data-direction={-1}
            style={{ backgroundImage: `url(${Image4})` }}
          />
        </div>
      </div>
      <div className={s.images}>
        <div className={s.image}>
          <div
            className={s.image__parallax}
            data-direction={1}
            style={{ backgroundImage: `url(${Image5})` }}
          />
        </div>
        <div className={s.image}>
          <div
            className={s.image__parallax}
            data-direction={-1}
            style={{ backgroundImage: `url(${Image6})` }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageParallax;
