
import React, { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import Matter from "matter-js";
// import MatterWrap from "matter-wrap";

import CookieImage from "../../images/cookie.png";
import CookieImageX from "../../images/cookie-x.png";

import s from "./CookiesMatter.module.scss";

const CookiesMatter = () => {

  const [cookiesVisible, showCookies] = useState(false);
  const [triggerVisible, showTrigger] = useState(true);
  const [clicks, setClicks] = useState(0);
  const no = ["no", "orly?", "srsly?", "cmon!", "meh", "kthxbai", "yes!"];

  useEffect(() => {

    if (!cookiesVisible) return () => {}

    showTrigger(false);
    // Matter.use(MatterWrap);

    const el = {
      borderBottom: null,
      borderLeft: null,
      borderRight: null,
      cookies: null,
    };

    let engine = null;
    let mouseConstraint = null;
    let render = null;
    let runner = null;
    let world = null;
    const wrapper = document.querySelector(`.${s.cookies}`);

    let sceneWidth = window.innerWidth;
    let sceneHeight = window.innerHeight;

    const cookieTextureDiameter = 300;
    let cookieColumns = sceneWidth <= 992 ? sceneWidth <= 774 ? 4 : 6 : 8;
    let cookieDiameter = sceneWidth / cookieColumns;
    const cookieRows = Math.round(sceneHeight / cookieDiameter) * 2;
    const cookieGap = 15;

    const cookieImage = new Image();
    const cookieImageX = new Image();

    // const fitToBounds = () => {
    //   // wrapping using matter-wrap plugin
    //   Matter.Composite.allBodies(world).forEach(body => {
    //     body.plugin.wrap = {
    //       min: { x: render.bounds.min.x }, //- cookieDiameter },
    //       max: { x: render.bounds.max.x }, //+ cookieDiameter },
    //       // min: { x: render.bounds.min.x - 100, y: render.bounds.min.y },
    //       // max: { x: render.bounds.max.x + 100, y: render.bounds.max.y },
    //     };
    //   });
    // };

    const fitToViewport = () => {
      // fit the render viewport to the scene
      Matter.Render.lookAt(render, {
        min: {
          x: 0,
          y: 0,
        },
        max: {
          x: sceneWidth,
          y: sceneHeight,
        },
      });
    };
    
    const renderScene = () => {
      // create engine
      engine = Matter.Engine.create();
      world = engine.world;
      world.gravity.y = 1.5;

      // create renderer
      render = Matter.Render.create({
        element: wrapper,
        engine: engine,
        options: {
          background: "transparent",
          hasBounds: false,
          width: sceneWidth,
          height: sceneHeight,
          // showAngleIndicator: true,
          // showBounds: true,
          // showCollisions: true,
          // showIds: true,
          // showPositions: true,
          wireframes: false,
        }
      });

      Matter.Render.run(render);

      // create runner
      runner = Matter.Runner.create();
      Matter.Runner.run(runner, engine);

      // add mouse control
      const mouse = Matter.Mouse.create(render.canvas);
      mouseConstraint = Matter.MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false,
          },
        },
      });

      Matter.World.add(world, mouseConstraint);

      // keep the mouse in sync with rendering
      render.mouse = mouse;
    };

    const renderStuff = () => {
      const borderOptions = {
        isStatic: true,
        label: "border",
        render: {
          fillStyle: "#000",
        },
      };

      el.borderBottom = Matter.Bodies.rectangle(sceneWidth / 2, sceneHeight + 30, sceneWidth + cookieDiameter * 2, 20, borderOptions);
      el.borderLeft = Matter.Bodies.rectangle(-cookieDiameter * .5, sceneHeight / 2, 20, sceneHeight * 4, borderOptions);
      el.borderRight = Matter.Bodies.rectangle(sceneWidth + cookieDiameter * .5, sceneHeight / 2, 20, sceneHeight * 4, borderOptions);

      el.cookies = Matter.Composites.stack(-cookieDiameter * .3, -sceneHeight * 3, cookieColumns, cookieRows, cookieGap, cookieGap, (x, y, columnIndex, rowIndex) => {
        const cookieSize = cookieDiameter / 2 - Matter.Common.random(1, 15);
        const cookie = Matter.Bodies.circle(x, y, cookieSize, {
          density: 0.01,
          friction: 0,
          frictionAir: 0.001 * Matter.Common.random(1, 10),
          frictionStatic: 4,
          label: rowIndex === cookieRows - 3 && columnIndex === cookieColumns - 3 ? "cookie close" : "cookie",
          restitution: 0,
          render: {
            sprite: {
              texture: cookieImage.src,
              xScale: cookieSize * 2 / cookieTextureDiameter,
              yScale: cookieSize * 2 / cookieTextureDiameter,
            }
          },
          slop: 0.5,
        });

        if (cookie.label.match(/close/g)) cookie.render.sprite.texture = cookieImageX.src;

        return cookie;
      });

      Matter.World.add(world, [
        el.borderBottom,
        el.borderLeft,
        el.borderRight,
        el.cookies,
      ]);
    };

    const adjustStuff = () => {
      // adjust borders
      const borderBottomScale = sceneWidth / (el.borderBottom.vertices[1].x - el.borderBottom.vertices[0].x);
      const borderSideScale = sceneHeight / (el.borderLeft.vertices[2].y - el.borderLeft.vertices[0].y);

      Matter.Body.scale(el.borderBottom, borderBottomScale, 1);
      Matter.Body.scale(el.borderLeft, 1, borderSideScale);
      Matter.Body.scale(el.borderRight, 1, borderSideScale);

      Matter.Body.setPosition(el.borderBottom, {
        x: sceneWidth / 2,
        y: sceneHeight + 30,
      });

      Matter.Body.setPosition(el.borderLeft, {
        x: -cookieDiameter * .5,
        y: sceneHeight / 2,
      });

      Matter.Body.setPosition(el.borderRight, {
        x: sceneWidth + cookieDiameter * .5,
        y: sceneHeight / 2,
      });

      // resize cookies
      el.cookies.bodies.forEach((body) => {
        const cookie = body;
        const scale = cookieDiameter / (cookie.circleRadius * 2);

        Matter.Body.scale(cookie, scale, scale);
        cookie.render.sprite.xScale = (cookie.circleRadius * 2 / cookieTextureDiameter) * scale;
        cookie.render.sprite.yScale = (cookie.circleRadius * 2 / cookieTextureDiameter) * scale;
      });
    };

    const resizeCanvas = debounce(123, () => {
      sceneWidth = window.innerWidth;
      sceneHeight = window.innerHeight;
      cookieColumns = sceneWidth <= 992 ? sceneWidth <= 774 ? 4 : 6 : 8;
      cookieDiameter = sceneWidth / cookieColumns;

      render.canvas.width = sceneWidth;
      render.canvas.height = sceneHeight;
      render.options.width = sceneWidth;
      render.options.height = sceneHeight;

      // fitToBounds();
      fitToViewport();
    });

    const removeCanvas = () => {
      Matter.Events.off(runner, "afterUpdate", handleClose);
      Matter.Events.off(runner, "afterUpdate", removeCookies);
      wrapper.innerHTML = "";
      showCookies(false);
      showTrigger(true);
      setClicks(0);
    };

    const removeCookies = () => {
      el.cookies.bodies.forEach((body) => {
        if (body.position.x < -cookieDiameter || body.position.x > sceneWidth + cookieDiameter || body.position.y < -cookieDiameter || body.position.y > sceneHeight + cookieDiameter) {
          Matter.Composite.remove(el.cookies, body);
        }
      });

      if (el.cookies.bodies.length < 1) removeCanvas();
    };

    const removeBorders = () => {
      Matter.Composite.allBodies(world).forEach((body) => {
        if (body.label.match(/border/g)) {
          Matter.Composite.remove(world, body);
        }
      });

      Matter.Events.off(runner, "afterUpdate", adjustStuff);
      Matter.Events.on(runner, "afterUpdate", removeCookies);
    };

    const triggerClose = () => {
      if (mouseConstraint.body && mouseConstraint.body.label.match(/close/g)) {
        removeBorders();

        // make close button push other cookies
        const close = mouseConstraint.body;
        Matter.Body.scale(close, 4, 2);
        setTimeout(() => {
          Matter.Body.scale(close, .25, .5);
          Matter.Events.off(mouseConstraint, "mousedown", triggerClose);
        }, 250);
      }
    };

    const handleClose = () => {
      const close = el.cookies.bodies.filter((body) => body.label.match(/close/g))[0];
      const closeX = close.position.x;
      const closeY = close.position.y;
      const mouseX = mouseConstraint.mouse.absolute.x;
      const mouseY = mouseConstraint.mouse.absolute.y;

      if (close && (mouseX > closeX - cookieDiameter / 2 && mouseX < closeX + cookieDiameter / 2 && mouseY > closeY - cookieDiameter / 2 && mouseY < closeY + cookieDiameter / 2)) {
        wrapper.style.cursor = "pointer";
      } else {
        wrapper.removeAttribute("style");
      }

      if (close && (close.position.x < -cookieDiameter || close.position.x > sceneWidth + cookieDiameter || close.position.y > sceneHeight + cookieDiameter)) {
        Matter.Events.off(runner, "afterUpdate", handleClose);
        removeBorders();
      }
    };

    // init
    const initCookies = () => {
      renderScene();
      renderStuff();
      // fitToBounds();
      fitToViewport();  
      
      Matter.Events.on(mouseConstraint, "mousedown", triggerClose);
      Matter.Events.on(runner, "afterUpdate", adjustStuff);
      Matter.Events.on(runner, "afterUpdate", handleClose);
      window.addEventListener("resize", resizeCanvas);
    };

    cookieImage.src = CookieImage;
    cookieImage.addEventListener("load", () => {
      cookieImageX.src = CookieImageX;
      cookieImageX.addEventListener("load", initCookies);
    });

    return () => {
      Matter.Events.off(mouseConstraint, "mousedown", triggerClose);
      Matter.Events.off(runner, "afterUpdate", adjustStuff);
      Matter.Events.off(runner, "afterUpdate", handleClose);
      Matter.Events.off(runner, "afterUpdate", removeCookies);
      window.removeEventListener("resize", resizeCanvas);
      resizeCanvas.cancel();
    };

  }, [cookiesVisible]);

  return (
    <div className={s.container}>
      <div className={s.cookies} />
      {triggerVisible && (
        <div className={s.trigger}>
          <div>Accept cookies?</div>
          <div>
            <button onClick={() => showCookies(true)}>yes</button>
            <button onClick={() => {
              if (clicks === no.length - 1) {
                showCookies(true);
              } else {
                setClicks(clicks => clicks + 1);
              }
            }}>{no[clicks]}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesMatter;
