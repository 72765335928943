
import React, { useEffect } from "react";
import { Power3, TweenMax } from "gsap";
import { throttle } from "throttle-debounce";

import s from "./PerspectiveShift.module.scss";

const PerspectiveShift = () => {
  
  useEffect(() => {

    const amount = 400;
    const dots = document.querySelector(`.${s.dots}`);
    
    const renderDots = () => {
      let count = 0;

      do {
        dots.insertAdjacentHTML("beforeend", `<div class=${s.dot}></div>`);
        count++;
      } while (count < amount);      
    };

    const moveDots = throttle(30, (event) => {
      const reversed = true; // optional direction for mouse follow
      const x = reversed ? -Math.floor((event.pageX / window.innerWidth) * 100) + 100 : Math.floor((event.pageX / window.innerWidth) * 100);
      const y = reversed ? -Math.floor((event.pageY / window.innerHeight) * 100) + 100 : Math.floor((event.pageY / window.innerHeight) * 100);
      
      TweenMax.to(dots, 2, {
        perspectiveOrigin: `${x}% ${y}%`,
        ease: Power3.easeOut,
      });
    });

    renderDots();
    moveDots({ pageX: window.innerWidth / 2, pageY: window.innerHeight / 2 });
    window.addEventListener("mousemove", moveDots);

    return () => {
      window.removeEventListener("mousemove", moveDots);
      moveDots.cancel();
    }

  }, []);
  
  return (
    <div className={s.container}>
      <div className={s.dots} />
    </div>
  );
};

export default PerspectiveShift;
