
import React, { useEffect } from "react";
import { Linear, Power2, TweenMax } from "gsap";

import s from "./InteractiveScrollyBackground.module.scss";

const InteractiveScrollyBackground = () => {

  useEffect(() => {

    const maxAngle = 66;
    const tileSize = 2;
    const lineColor = "rgba(222, 222, 222, .33)";
    const lineWidth = tileSize / 14;
    let scrollTimeout = null;
    let scrollDelta = 0;
    let scrollTop = -document.body.getBoundingClientRect().top;

    const getDelta = () => {
      const scrollOld = scrollTop;
      scrollTop = -document.body.getBoundingClientRect().top;
      const scrollNew = scrollTop;
      scrollDelta = scrollNew - scrollOld;
    };

    const moveGradient = () => {
      const tileSizeUpdated = Math.round(tileSize + Math.abs(scrollDelta) / 22);
      const lineRotation = scrollDelta > maxAngle ? maxAngle : scrollDelta < -maxAngle ? -maxAngle : scrollDelta;
      
      TweenMax.to(`.${s.background}`, 1, {
        attr: {
          style: `background: 50% 50% / ${tileSizeUpdated}vw ${tileSizeUpdated}vw transparent linear-gradient(${lineRotation}deg, transparent ${tileSizeUpdated / 2 - lineWidth}vw, ${lineColor} ${tileSizeUpdated / 2 - lineWidth}vw, ${lineColor} ${tileSizeUpdated / 2}vw, transparent ${tileSizeUpdated / 2}vw, transparent) repeat fixed`,
        },
        ease: Linear.easeNone,
      });
    };

    const resetGradient = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        TweenMax.to(`.${s.background}`, 1, {
          attr: {
            style: `background: 50% 50% / ${tileSize}vw ${tileSize}vw transparent linear-gradient(0deg, transparent ${tileSize / 2 - lineWidth}vw, ${lineColor} ${tileSize / 2 - lineWidth}vw, ${lineColor} ${tileSize / 2}vw, transparent ${tileSize / 2}vw, transparent) repeat fixed`,
          },
          ease: Power2.easeOut,
        });
      }, 100);
    };

    resetGradient();

    window.addEventListener("scroll", getDelta);
    window.addEventListener("scroll", moveGradient);
    window.addEventListener("scroll", resetGradient);

    return () => {
      window.removeEventListener("scroll", getDelta);
      window.removeEventListener("scroll", moveGradient);
      window.removeEventListener("scroll", resetGradient);
    }

  }, []);

  return (
    <div className={s.container}>
      <div className={s.text}>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin diam quam, maximus ac porttitor at, suscipit ac leo. Suspendisse vestibulum tellus id ullamcorper varius. Nam tincidunt turpis ipsum, ut pharetra magna scelerisque ac. Vivamus semper mi sit amet tortor pretium, vel condimentum turpis porta. Praesent pulvinar ipsum dolor, in pretium urna eleifend sit amet. Donec luctus scelerisque sapien, at aliquam nunc tempus ac. Morbi vitae risus mi. Donec convallis eget urna sed sodales. Ut dui urna, efficitur ut sollicitudin vel, imperdiet a arcu.</p>
        <p>Nulla facilisi. Sed ut massa ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla elementum, leo eget cursus sodales, erat elit tristique metus, quis sodales purus nibh ut ex. Curabitur erat justo, blandit tincidunt scelerisque sed, egestas non magna. Etiam at ultricies nulla. Fusce id pellentesque tellus. Donec nulla nulla, cursus id convallis eget, pharetra id neque. Mauris consectetur nulla et eros cursus, a tincidunt neque mollis. Curabitur feugiat feugiat tellus ac finibus. Vestibulum eget velit egestas, sollicitudin tellus quis, tincidunt turpis. Etiam sed nulla augue. Nullam laoreet, nibh ac auctor dictum, mauris lectus vestibulum diam, eget vestibulum erat purus a massa.</p>
        <p>Vestibulum tempus nibh diam, ac dapibus arcu semper nec. Duis in egestas magna, sed volutpat ipsum. Integer ac auctor diam. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla facilisi. Nam a convallis sapien, nec vulputate neque. Integer laoreet nibh a dui accumsan, porta sollicitudin libero dictum. Donec posuere cursus interdum. Fusce dignissim nisl et massa sollicitudin, eu aliquet ligula hendrerit.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin diam quam, maximus ac porttitor at, suscipit ac leo. Suspendisse vestibulum tellus id ullamcorper varius. Nam tincidunt turpis ipsum, ut pharetra magna scelerisque ac. Vivamus semper mi sit amet tortor pretium, vel condimentum turpis porta. Praesent pulvinar ipsum dolor, in pretium urna eleifend sit amet. Donec luctus scelerisque sapien, at aliquam nunc tempus ac. Morbi vitae risus mi. Donec convallis eget urna sed sodales. Ut dui urna, efficitur ut sollicitudin vel, imperdiet a arcu.</p>
        <p>Nulla facilisi. Sed ut massa ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla elementum, leo eget cursus sodales, erat elit tristique metus, quis sodales purus nibh ut ex. Curabitur erat justo, blandit tincidunt scelerisque sed, egestas non magna. Etiam at ultricies nulla. Fusce id pellentesque tellus. Donec nulla nulla, cursus id convallis eget, pharetra id neque. Mauris consectetur nulla et eros cursus, a tincidunt neque mollis. Curabitur feugiat feugiat tellus ac finibus. Vestibulum eget velit egestas, sollicitudin tellus quis, tincidunt turpis. Etiam sed nulla augue. Nullam laoreet, nibh ac auctor dictum, mauris lectus vestibulum diam, eget vestibulum erat purus a massa.</p>
        <p>Vestibulum tempus nibh diam, ac dapibus arcu semper nec. Duis in egestas magna, sed volutpat ipsum. Integer ac auctor diam. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla facilisi. Nam a convallis sapien, nec vulputate neque. Integer laoreet nibh a dui accumsan, porta sollicitudin libero dictum. Donec posuere cursus interdum. Fusce dignissim nisl et massa sollicitudin, eu aliquet ligula hendrerit.</p>
      </div>
      <div className={s.background} />
    </div>
  );
};

export default InteractiveScrollyBackground;
