
import React, { useEffect } from "react";

import s from "./Print.module.scss";

const Print = () => {

  const emojis = [
    "  (^'-')> ",
    "  (> '-') >",
    "  (v'-')> ",
    "  (> '-') >",
    " <('-'^)  ",
    "< ('-' <) ",
    " <('-'v)  ",
  ];

  useEffect(() => {

    const button = document.querySelector(`.${s.container} button`);
    const print = () => window.print();
    button.addEventListener("click", print);

    return () => {
      button.removeEventListener("click", print);
    };

  }, []);

  return (
    <div className={s.container}>
      <button>🖨️</button>
      <ul className={s.pages}>
        {Array.from({ length: 4 }, (_, i) => {
          return emojis.map((emoji, j) => (
            <li key={`emoji-${i}-${j}`}>
              {emoji.replace(/ /g, "\u00a0")}
            </li>
          ));
        })}
      </ul>
    </div>
  );
};

export default Print;
