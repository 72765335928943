
import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";

import s from "./ExternalLinkIcon.module.scss";
import { PageContext } from "../../layout";

const ExternalLinkIcon = () => {

  const page = useContext(PageContext);

  useEffect(() => {

    const links = document.querySelectorAll(`.${s.container} a[target="_blank"]`);
    const markup = "<i><div></div><div></div><div></div><div></div><div></div></i>";

    Array.prototype.forEach.call(links, (link) => {
      link.insertAdjacentHTML("beforeend", markup);
    });

  }, []);

  return (
    <div className={s.container}>
      <ul>
        <li><a href="http://ccrch.com" target="_blank" rel="noreferrer noopener">External url</a></li>
        <li><Link onClick={page.delayedRedirect} to="/">Internal link</Link></li>
      </ul>
    </div>
  );
};

export default ExternalLinkIcon;
