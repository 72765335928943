
import React, { useEffect } from "react";
import { Linear, Power4, TweenLite, TimelineLite } from "gsap";

import Image1 from "../../images/66-north-1.jpg";
import Image2 from "../../images/66-north-2.jpg";

import s from "./SVGFilterEffects.module.scss";

const SVGFilterEffects = () => {

  useEffect(() => {
    
    const images = document.querySelectorAll(`.${s.image}`);

    // const counter = { nr: 0 };

    // TweenMax.to(counter, 1.8, {
    //   nr: 50,
    //   // roundProps: "nr",
    //   ease: Power3.easeOut,
    //   onUpdate: () => {
    //     // setDiscount(counter.nr < 10 ? `0${counter.nr}` : counter.nr),
    //     console.log(counter.nr)
    //   },
    // });

    const handleHover = (event) => {
      const animation = new TimelineLite();
      const imageBottom = event.target.querySelector("image:first-of-type");
      const imageTop = event.target.querySelector("image:last-of-type");
      const turbulence = event.target.querySelector("filter feTurbulence");

      TweenLite.defaultEase = Linear.easeNone;
      animation.timeScale(1.2);

      animation
        .to(event.type === "mouseover" ? imageTop : imageBottom, .5, {
          attr: { opacity: 0 },
        }, 0)
        .to(turbulence, .25, {
          attr: { baseFrequency: .075 },
        }, 0)
        .to(event.type === "mouseover" ? imageBottom : imageTop, .5, {
          attr: { opacity: 1 },
        }, 0)
        .to(turbulence, .5, {
          attr: { baseFrequency: 0 },
          ease: Power4.easeOut,
        }, .25)
        .to(event.target.parentElement, .75, {
          ease: Power4.easeOut,
          scale: event.type === "mouseover" ? .95 : 1,
        }, 0);
    };

    images.forEach(image => {
      image.addEventListener("mouseover", handleHover);
      image.addEventListener("mouseout", handleHover);
    });    

    return () => {
      images.forEach(image => {
        image.removeEventListener("mouseover", handleHover);
        image.removeEventListener("mouseout", handleHover);
      });
    }

  }, []);

  // list of available filters // https://www.w3schools.com/graphics/svg_filters_intro.asp
  // turbulence + displacement map // https://codepen.io/SaraSoueidan/pen/17f70e3518f28ccbd2d820fbe41ffa08
  // swirly dog // https://codepen.io/enxaneta/post/svg-waves-with-fedisplacementmap
  // basics, blur // https://tympanus.net/codrops/2019/01/15/svg-filters-101/ 

  return (
    <div className={s.container}>
      <div className={s.images}>
        <div className={s.image}>
          <svg viewBox="0 0 400 350" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
            <filter id="noise" x="-10%" y="-10%" width="120%" height="120%" filterUnits="objectBoundingBox">
              <feTurbulence baseFrequency="0" result="NOISE" numOctaves="9"/>
              <feDisplacementMap in="SourceGraphic" in2="NOISE" scale="120" xChannelSelector="R" yChannelSelector="R" />
            </filter>
            <image xlinkHref={Image1} filter="url(#noise)" opacity="0" />
            <image xlinkHref={Image2} filter="url(#noise)" opacity="1" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SVGFilterEffects;
