
import React, { useEffect } from "react";
import classNames from "classnames";
import { Back, Elastic, Linear, Power3, TimelineMax, TweenMax, TweenLite } from "gsap";

import s from "./TimelineScroll.module.scss";

const TimelineScroll = () => {

  useEffect(() => {

    const initialPositions = new TimelineMax();
    const timelineAnimation = new TimelineMax();
    const scrollSpeedMod = .75; // 1 = 100%
    let currentScrollPosition = 0;

    const c = {
      blue: "#003049",
      red: "#d62828",
      orange: "#f77f00",
      yellow: "#fcbf49",
      white: "#eae2b7",
    };

    const init = () => {

      TweenMax.set(`.${s.page}, .${s.page} *`, { clearProps: "all" });
      TweenLite.defaultEase = Linear.easeNone;

      initialPositions
        .clear()
        .set(`.${s.circle}`, { scale: 0 })
        .set(`.${s.circle__text}`, { scale: 5, opacity: 0 })
        .set(`.${s.section____2}`, { y: "100%" })
        .set(`.${s.section____3}, .${s.section____4}`, { opacity: 0 })
        .set(`.${s.section____4} li`, { scale: 10, opacity: 0 })
        .to(`.${s.page}`, 2, {
          opacity: 1,
          ease: Power3.easeOut,
          onComplete: () => {
            document.querySelector(`.${s.page}`).classList.add(s.page____loaded);
          }
        });

      timelineAnimation
        .clear()
        .pause()
        .to(`.${s.circle}`, 1, { scale: 1, ease: Elastic.easeOut })
        .to(`.${s.circle__text____1}`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut })
        .to(`.${s.circle__text____1}`, .5, { scale: 0, opacity: 0, ease: Power3.easeIn })
        .to(`.${s.circle__text____2}`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut }, "-=.25")
        .to(`.${s.circle__text____2}`, .5, { scale: 0, opacity: 0, ease: Power3.easeIn })
        .to(`.${s.circle__text____3}`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut }, "-=.25")
        .to(`.${s.circle__text____3}`, .5, { scale: 0, opacity: 0, ease: Power3.easeIn })
        .to(`.${s.circle__text____4}`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut }, "-=.25")
        .to(`.${s.circle__text____4}`, .5, { scale: 0, opacity: 0, ease: Power3.easeIn })
        .to(`.${s.circle}`, 1, { scale: 0, ease: Back.easeIn }, "-=.25")
        .to(`.${s.section____1}`, 1, { y: "-100%" })
        .to(`.${s.section____2}`, 1, { y: "0%" }, "-=.5")
        .set(`.${s.section____3}`, { opacity: 1 })
        .to(`.${s.section____2} .${s.text} > div > div`, 4, { y: "-120%", ease: Power3.easeInOut })
        .to(`.${s.section____2}`, 1, { backgroundColor: c.white, color: "#fff" }, "-=3") 
        .to(`.${s.section____2}`, .5, { opacity: 0 }, "-=1.25")
        .set(`.${s.section____4}`, { opacity: 1 })
        .to(`.${s.section____3} .${s.text} p`, 4, { x: "-100%" }, "-=1.75")
        .to(`.${s.section____3}`, 1, { backgroundColor: c.red }, "-=2.5")
        .to(`.${s.section____3}`, 1, { y: "100%" }, "-=.75")
        .to(`.${s.section____4} li:nth-child(1)`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut })
        .to(`.${s.section____4} li:nth-child(2)`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut }, "-=.25")
        .to(`.${s.section____4} li:nth-child(3)`, .5, { scale: 1, opacity: 1, ease: Power3.easeOut }, "-=.25")
        .to(`.${s.section____4} li:nth-child(4)`, 1, { scale: 1, opacity: 1, ease: Power3.easeOut }, "-=.25")
        .to(`.${s.section____4} li:nth-child(1)`, 1, { color: c.red }, "-=.25")
        .to(`.${s.section____4} li:nth-child(2)`, 1, { color: c.orange }, "-=.75")
        .to(`.${s.section____4} li:nth-child(3)`, 1, { color: c.yellow }, "-=.75")
        .to(`.${s.section____4}`, 1, { backgroundColor: c.white }, "-=.5")
        .to(`.${s.section____4} li:nth-child(4)`, 1, { color: c.blue }, "-=.75")
        .set(`.${s.page}`, { backgroundColor: c.blue})
        .to(`.${s.section____4}`, 1, { opacity: 0 });

      handleHeight();
    }

    const handleHeight = () => {
      const duration = timelineAnimation.duration() / scrollSpeedMod;
      const pageHeight = window.innerHeight * (duration + 1);

      TweenMax.set(`.${s.page}`, { height: pageHeight });
    };

    const handleScroll = () => {
      currentScrollPosition = -document.body.getBoundingClientRect().top / window.innerHeight * scrollSpeedMod;
      timelineAnimation.seek(currentScrollPosition);
    };

    const handleResize = () => {
      const scrollPosition = -document.body.getBoundingClientRect().top;
      init();
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 0);
    };

    init();

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      initialPositions.clear();
      timelineAnimation.clear();
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    }

  }, []);

  return (
    <div className={s.page}>
      <section className={classNames(s.section, s.section____1)}>
        <div className={s.circle}>
          <p className={classNames(s.circle__text, s.circle__text____1)}>KEEP</p>
          <p className={classNames(s.circle__text, s.circle__text____2)}>ON</p>
          <p className={classNames(s.circle__text, s.circle__text____3)}>SCROLL</p>
          <p className={classNames(s.circle__text, s.circle__text____4)}>IN&#39;</p>
        </div>
      </section>
      <section  className={classNames(s.section, s.section____2)}>
        <div className={s.text}>
          <div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sodales velit eget massa auctor, a laoreet risus faucibus. Nullam placerat fermentum quam at tristique. Donec et lectus a velit posuere aliquam in dictum neque. Nullam sodales neque id magna facilisis, sed finibus est feugiat. Maecenas ac dui bibendum, dapibus eros ut, commodo tellus. Sed sed pulvinar massa. Nullam tempus pretium sodales. Nam viverra iaculis elit. Sed iaculis lacus ac mauris pulvinar, in volutpat mauris ultrices. Fusce quam ex, sodales quis varius vitae, consectetur vel tortor.</p>
              <p>Massa auctor, a laoreet risus faucibus. Nullam placerat fermentum quam at tristique. Donec et lectus a velit posuere aliquam in dictum neque. Nullam sodales neque id magna facilisis, sed finibus est feugiat. Maecenas ac dui bibendum, dapibus eros ut, commodo tellus. Sed sed pulvinar massa. Nullam tempus pretium sodales. Nam viverra iaculis elit. Sed iaculis lacus ac mauris pulvinar, in volutpat mauris ultrices. Fusce quam ex, sodales quis varius vitae, consectetur vel tortor. Donec molestie leo ac velit eleifend, venenatis facilisis velit fermentum. Vestibulum molestie enim nisl, quis condimentum dui dignissim at. Nullam fringilla, est nec pulvinar pellentesque, tortor quam vestibulum velit, a mollis urna mauris eu augue.</p>
              <p>Dapibus eros ut, commodo tellus. Sed sed pulvinar massa. Nullam tempus pretium sodales. Nam viverra iaculis elit. Sed iaculis lacus ac mauris pulvinar, in volutpat mauris ultrices. Fusce quam ex, sodales quis varius vitae, consectetur vel tortor. Donec molestie leo ac velit eleifend, venenatis facilisis velit fermentum. Vestibulum molestie enim nisl, quis condimentum dui dignissim at. Nullam fringilla, est nec pulvinar pellentesque, tortor quam vestibulum velit, a mollis urna mauris eu augue.</p>
            </div>
          </div>
          <div>
            <div>
              <p>Maecenas ac dui bibendum, dapibus eros ut, commodo tellus. Sed sed pulvinar massa. Nullam tempus pretium sodales. </p>
              <p>Donec molestie leo ac velit eleifend, venenatis facilisis velit fermentum. Vestibulum molestie enim nisl, quis condimentum dui dignissim at. Nullam fringilla, est nec pulvinar pellentesque, tortor quam vestibulum velit, a mollis urna mauris eu augue.</p>
            </div>
          </div>
        </div>
      </section>
      <section  className={classNames(s.section, s.section____3)}>
        <div className={s.text}>
          <p>Lorem ipsum dolor sit amet.</p>
        </div>
      </section>
      <section  className={classNames(s.section, s.section____4)}>
        <ul>
          <li>D</li>
          <li>O</li>
          <li>N</li>
          <li>E</li>
        </ul>
      </section>
    </div>
  );
};

export default TimelineScroll;
