
import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types"; 
import { graphql } from "gatsby";
import { Power4, TweenMax } from "gsap";

import { Error, PageContext, SEO } from "..";

import Accordion from "../../components/accordion/Accordion";
import BlurryButtons from "../../components/blurry-buttons/BlurryButtons";
import ChevronToBox from "../../components/chevron-to-box/ChevronToBox";
import Configurator from "../../components/configurator/Configurator";
import CookiesMatter from "../../components/cookies-matter/CookiesMatter";
import CrawlingLine from "../../components/crawling-line/CrawlingLine";
// import DominosGift from "../../components/dominos-gift/DominosGift";
// import DominosMegavika from "../../components/dominos-megavika/DominosMegavika";
import ExternalLinkIcon from "../../components/external-link-icon/ExternalLinkIcon";
import FixedClippedHeaders from "../../components/fixed-clipped-headers/FixedClippedHeaders";
import GradientBackground from "../../components/gradient-background/GradientBackground";
import GradientHeadline from "../../components/gradient-headline/GradientHeadline";
import ImageParallax from "../../components/image-parallax/ImageParallax";
import InteractiveScrollyBackground from "../../components/interactive-scrolly-background/InteractiveScrollyBackground";
import KosmosOgKaos from "../../components/kosmos-og-kaos/KosmosOgKaos";
import LinkedInCorner from "../../components/linkedin-corner/LinkedInCorner";
import LobsterType from "../../components/lobster-type/LobsterType";
// import LocomotiveScrollTest from "../../components/locomotive-scroll-test/LocomotiveScrollTest";
import MousemoveFloatingAround from "../../components/mousemove-floating/MousemoveFloatingAround";
import MousemoveFloatingAway from "../../components/mousemove-floating/MousemoveFloatingAway";
import ParallaxySvgBackground from "../../components/parallaxy-svg-background/ParallaxySvgBackground";
import PerspectiveShift from "../../components/perspective-shift/PerspectiveShift";
import PixelExplosion from "../../components/pixel-explosion/PixelExplosion";
import Print from "../../components/print/Print";
import ResponsiveCollapsibleGrid from "../../components/responsive-collapsible-grid/ResponsiveCollapsibleGrid";
import ResponsiveInlineNavigation from "../../components/responsive-inline-navigation/ResponsiveInlineNavigation";
import RocketFlyBy from "../../components/rocket-fly-by/RocketFlyBy";
import ScrollLoop from "../../components/scroll-loop/ScrollLoop";
import Siteception from "../../components/siteception/Siteception";
import Skapalon from "../../components/skapalon/Skapalon";
import SmoothScroll from "../../components/smooth-scroll/SmoothScroll";
import SVGFilterEffects from "../../components/svg-filter-effects/SVGFilterEffects";
import TimelineScroll from "../../components/timeline-scroll/TimelineScroll";
import TsunamiNavigation from "../../components/tsunami-navigation/TsunamiNavigation";
import VideoScroll from "../../components/video-scroll/VideoScroll";
import ZoomAndPan from "../../components/zoom-and-pan/ZoomAndPan";

import s from "./ComponentTemplate.module.scss";

const ComponentTemplate = ({ data }) => {
  const { hidden, id, name } = data.allSitePage.edges[0].node.context;

  const elCover = useRef(null);
  const page = useContext(PageContext);
  
  const renderComponent = (id) => {
    switch (id) {
      case "accordion": return <Accordion />
      case "blurry-buttons": return <BlurryButtons />
      case "chevron-to-box": return <ChevronToBox />
      case "cookies-matter": return <CookiesMatter />
      case "crawling-line": return <CrawlingLine />
      // {
      //   "id": "dominos-gift",
      //   "name": "Domino's Gift",
      //   "date": "2020.10.22",
      //   "description": "<p>Pantaðu núna!</p>",
      //   "hidden": true
      // },
      // {
      //   "id": "dominos-megavika",
      //   "name": "Domino's Megavika",
      //   "date": "2019.08.10",
      //   "description": "<p>Pantaðu núna!</p>",
      //   "hidden": true
      // },
      // case "dominos-megavika": return <DominosMegavika Link="/" />
      case "external-link-icon": return <ExternalLinkIcon />
      case "fixed-clipped-headers": return <FixedClippedHeaders />
      case "gradient-background": return <GradientBackground />
      case "gradient-headline": return <GradientHeadline />
      case "image-parallax": return <ImageParallax />
      case "interactive-scrolly-background": return <InteractiveScrollyBackground />
      case "kosmos-og-kaos": return <KosmosOgKaos />
      case "linkedin-corner": return <LinkedInCorner />
      case "lobster-type": return <LobsterType />
      // {
      //   "id": "locomotive-scroll-test",
      //   "name": "Locomotive Scroll Test",
      //   "date": "2020.09.04",
      //   "description": "<p>🚂🚃🚃🚃... 👌!</p>",
      //   "hidden": false
      // },
      // case "locomotive-scroll-test": return <LocomotiveScrollTest />
      case "mousemove-floating-around": return <MousemoveFloatingAround />
      case "mousemove-floating-away": return <MousemoveFloatingAway />
      case "parallaxy-svg-background": return <ParallaxySvgBackground />
      case "perspective-shift": return <PerspectiveShift />
      case "pixel-explosion": return <PixelExplosion />
      case "print": return <Print />
      case "responsive-collapsible-grid": return <ResponsiveCollapsibleGrid />
      case "responsive-inline-navigation": return <ResponsiveInlineNavigation />
      case "rocket-fly-by": return <RocketFlyBy />
      case "scroll-loop": return <ScrollLoop />
      case "siteception": return <Siteception />
      case "skapalon": return <Skapalon />
      case "smooth-scroll": return <SmoothScroll />
      case "svg-filter-effects": return <SVGFilterEffects />
      case "timeline-scroll": return <TimelineScroll />
      case "tsunami-navigation": return <TsunamiNavigation />
      case "configurator": return <Configurator />
      case "video-scroll": return <VideoScroll />
      case "zoom-and-pan": return <ZoomAndPan />
    }
  };

  useEffect(() => {

    page.toggleCurrentRoute(window.location.pathname);

    TweenMax.set(elCover.current, { clearProps: "all" });
    TweenMax.fromTo(elCover.current.firstElementChild, .3, {
      x: "0%",
    }, {
      delay: .1,
      ease: Power4.easeInOut,
      onComplete: () => TweenMax.set(elCover.current, { zIndex: -1 }),
      x: "100%",
    });

  }, []);

  return (
    <>
      <SEO title={name} />
      {hidden && process.env.mode !== "dev" ? (
        <Error>
          This component is currently unavailable. Sorry :/
        </Error>
      ) : (
        renderComponent(id)
      )}
      <div className={s.cover} ref={elCover}>
        <div />
      </div>
    </>
  );
};

ComponentTemplate.propTypes = {
  data: PropTypes.object,
};

export default ComponentTemplate;

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            id
            name
            date
            description
            hidden
          }
        }
      }
    }
  }
`
