
import React, { useEffect } from "react";
import { TweenMax, Power4 } from "gsap";
import { throttle } from "throttle-debounce";

import Background from "./background.svg";

import s from "./ParallaxySvgBackground.module.scss";

const ParallaxySvgBackground = () => {

  useEffect(() => {

    const background = document.querySelector(`.${s.background} > div`);
    const backgroundElements = background.querySelectorAll("[data-id]");
    const cover = document.querySelector(`.${s.cover}`);
    const parallaxyElement = background.querySelectorAll("[data-parallax]");
    let isHovered = false;
    
    setTimeout(() => {
      background.innerHTML = "";

      backgroundElements.forEach((element, index) => {
        background.insertAdjacentHTML("beforeend", `<svg width="4090" height="2400" viewBox="0 0 4090 2400" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" data-layer="${index}"></svg>`);
        const svg = background.querySelector(`svg[data-layer="${index}"]`);
        svg.insertAdjacentElement("beforeend", element);
      });

      TweenMax.to(cover, .5, {
        ease: Power4.easeInOut,
        x: "100%",
      });

      background.parentElement.addEventListener("mouseenter", enableMouseMove);
      background.parentElement.addEventListener("mouseleave", disableMouseMove);
    }, 500);

    const disableMouseMove = () => {
      isHovered = false;
    };

    const enableMouseMove = () => {
      isHovered = true;
    };

    const handleMouseMove = throttle(33, (event) => {
      if (!isHovered) return;

      const x = event.touches ? event.touches[0].clientX : event.clientX;
      const y = event.touches ? event.touches[0].clientY : event.clientY;

      const xPercent = (x / window.innerWidth - 0.5) * 100;
      const yPercent = (y / window.innerHeight - 0.5) * 100;

      parallaxyElement.forEach(element => {
        const mod = Number(element.getAttribute("data-parallax")) / 4;

        TweenMax.to(element.parentNode, 2, {
          x: `${-xPercent / mod}%`,
          y: `${-yPercent / mod}%`,
        });
      });
    });

    const handleMobileMove = throttle(33, () => {
      if (background.offsetWidth > 992) return;

      const maxAlpha = 60;
      const maxBeta = [-20, 100];
      const a = event.alpha > maxAlpha ? maxAlpha : event.alpha < -maxAlpha ? -maxAlpha : event.alpha;
      const b = event.beta > maxBeta[1] ? maxBeta[1] : event.beta < maxBeta[0] ? maxBeta[0] : event.beta;

      parallaxyElement.forEach(element => {
        const mod = Number(element.getAttribute("data-parallax")) / 3;

        TweenMax.to(element.parentNode, 2, {
          x: `${-a / mod}%`,
          y: `${(-b + 40) / mod}%`,
        });
      });
    });

    window.addEventListener("deviceorientation", handleMobileMove);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("deviceorientation", handleMobileMove);
      window.removeEventListener("mousemove", handleMouseMove);
      handleMobileMove.cancel();
      handleMouseMove.cancel();
    };

  }, []);

  return (
    <div className={s.container}>
      <div className={s.background}>
        <div>
          <Background preserveAspectRatio="xMidYMid slice" />
        </div>
        <div className={s.cover} />
      </div>
    </div>
  );
};

export default ParallaxySvgBackground;
