
import React, { useEffect } from "react";
import { Power3, TimelineMax } from "gsap";

import s from "./GradientHeadline.module.scss";

const GradientHeadline = () => {

  useEffect(() => {
    
    const el = {
      headline: document.querySelector(`.${s.container} h1 span`),
      image: document.querySelector(`.${s.background} div`),
    };
    
    const animation = new TimelineMax();

    animation
      .set(el.headline, { x: "-40%", scale: .75, transition: "none", pointerEvents: "none" })
      .set(el.image, { scale: 1, transition: "none" })
      .to(el.headline, 4, { x: "-10%", scale: 1, ease: Power3.easeOut, clearProps: "all" }, 0)
      .to(el.image, 4, { scale: 1.1, ease: Power3.easeOut, clearProps: "transform, transition" }, 0);

  }, []);

  return (
    <div className={s.container}>
      <h1>
        <span>Headline</span>
      </h1>
      <div className={s.background}>
        <div />
      </div>
    </div>
  );
};

export default GradientHeadline;
